import React, { useState, useEffect } from "react";
import { StyledCollectionLotStatus } from "./CollectionLotStatus.styled";
import fahh1 from "./../../assets/fahh1.png";
import { useSelector } from "react-redux";
import social_icon from "./../../assets/coming-soon/social_icon.png";
import Footer_icon from "./../../assets/coming-soon/footer_icon.svg";
import social_icon1 from "./../../assets/coming-soon/social_icon1.png";
import social_icon2 from "./../../assets/coming-soon/social_icon2.png";
import social_icon3 from "./../../assets/coming-soon/social_icon3.png";
import FAHH_logo_color_2 from "./../../assets/coming-soon/FAHH_logo_color_2.svg";
import Rock_the_Bells1 from "./../../assets/coming-soon/Rock_the_Bells1.svg";
import { triggerMixpanelEvent } from "../../Scenes/common";
import Fahh_coming_soon_12 from "./../../assets/coming-soon/fahh_coming_soon_12.svg";
import { Modal, Button } from "react-bootstrap";
import * as signalR from "@microsoft/signalr";
import { SERVER_IP } from "./../../library/constants.js";

import {
  getCollectionItemsCall,
  getCollectionByIdCall,
  getcurrenciesCall,
  getcollectionitembyidCall,
  followbidCall,
  getTimeZones,
  unfollowbidCall,
  getItemidsbyCollectionIdCall,
} from "./../../library/Requests";
import moment from "moment";
import LotBox from "./LotBox";
import Loader from "./../../components/Common/Loader";
import { ENV_SHOT_CODE } from "./../../config";
import Select from "react-select";

const CollectionLotStatus = (props: any) => {
  const [collectionData, setCollectionData] = useState({} as any);
  const [collectionItems, setCollectionItems] = useState([] as any);
  const [collectionItemsFull, setCollectionItemsFull] = useState([] as any);
  const [timezonesList, setTimezonesList] = useState([] as any);
  const [currenciesList, setCurrenciesList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [searchV, setSearchV] = useState("");
  const [allLotSnos, setAllLotSnos] = useState([] as any);
  const [lotsList, setLotsList] = useState([] as any);

  useEffect(() => {
    const id = props?.match?.params?.id;
    setIsLoading(true);
    getCollectionData();
    getStaticData();
    getAllLotItemsDataIds();
    localStorage.removeItem("newInter_Values_lot_box");
  }, []);

  useEffect(() => {
    // if (myLot1?.id !== myLot?.id && myLot?.id) {
    // getTimeDefault();

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .withAutomaticReconnect()
      .build();
    connection.on("BidInsertSignal", (response) => {});
    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          getCollectionAllItems(1, "signal", 200);
          // getCollectionItemSection(response?.itemId);
          console.log("signal responseresponse", response?.itemId);
        });
      })
      .catch((err) => console.error(err.toString()));
  }, []);
  const getAllLotItemsDataIds = async () => {
    const id = props?.match?.params?.id;
    let resp1 = await getItemidsbyCollectionIdCall(id);
    setAllLotSnos(resp1?.data);
    const newListIds = [] as any;
    (resp1?.data || []).map((item: any, index: any) => {
      newListIds.push({
        ...item,
        value: item.itemId,
        label: "Lot " + item.rno,
      });
    });

    setLotsList(newListIds);
  };

  const getStaticData = async () => {
    const timezoneResp = await getTimeZones();
    setTimezonesList(timezoneResp?.data);
    const resp3 = await getcurrenciesCall();
    setCurrenciesList(resp3?.data);
  };
  const handleViewMore = () => {
    const newPage = pageNo + 1;
    setPageNo(newPage);
    setIsLoading(true);
    // getCollectionItemSection(2329);
    getCollectionAllItems(newPage, "viewmore", null);
  };
  const getCollectionData = async () => {
    const id = props?.match?.params?.id;
    const resp = await getCollectionByIdCall(id, null, "collectionlotstatus");
    setCollectionData(resp?.data);
    getCollectionAllItems(pageNo, "", null);
  };
  const onChangeValueSelect = async (itemvvv: any) => {
    console.log("item", itemvvv);
    setSearchV(itemvvv?.label);
    if (itemvvv?.itemId) {
      setIsLoading(true);
      const newValue = localStorage.getItem("newInter_Values_lot_box");
      const newInterArray = newValue?.split("__");
      var newlistINtervals = [] as any;
      newInterArray?.map((IIMM: any) => {
        if (IIMM) {
          const interLotId = IIMM.split("_")[0];
          const interValId = IIMM.split("_")[1];
          window.clearInterval(parseInt(interValId));
        }
      });
      localStorage.removeItem("newInter_Values_lot_box");
      const collectionItem = await getcollectionitembyidCall(itemvvv?.itemId);
      const newList = [] as any;
      newList.push(collectionItem?.data);
      setCollectionItems(newList);
      setIsLoading(false);
      // this.setState({ isPageLoading: true });
      // this.getCollectionData(collectionItem?.data?.collectionId, updateType);
    }
  };
  const getCollectionItemSection = async (lotIDD: any) => {
    const collectionItemResp = await getcollectionitembyidCall(lotIDD);
    console.log("lot extented time ", collectionItemResp?.data);
    // setLotDetails(collectionItem?.data);
    const collData = collectionItemResp?.data;
    // setCollectionItems
    // collectionItems;
    const oldList = collectionItems;
    console.log("oldList", oldList);
    const newList = [] as any;
    collectionItems.map((respC: any) => {
      console.log("respC", respC);
      if (lotIDD === collData?.id) {
        newList.push(collData);
      } else {
        newList.push(respC);
      }
    });
    console.log("newListnewList", newList);
    setCollectionItems(newList);
  };
  const getCollectionAllItems = async (
    newPageN: any,
    typeV: any,
    pageSizeN: any
  ) => {
    const id = props?.match?.params?.id;
    setPageNo(newPageN);
    const body = {
      page: newPageN || pageNo,
      pageSize: pageSizeN || pageSize,
      getOnlyTotalCount: true,
      collectionId: id,
      sortColumn: "Sno",
      sortDirection: "asc",
      status: [1, 6, 7],
    };

    let resp = await getCollectionItemsCall(body);

    if (typeV == "viewmore") {
      // this.setState({
      //   isLoading: false,
      //   collectionItems: [...collectionItems, ...resp?.data],
      //   collectionItemsFull: resp,
      // });
      const newAll = [...collectionItems, ...resp?.data];
      setCollectionItems(newAll);
      setIsLoading(false);
    } else {
      setCollectionItems(resp?.data);
      setIsLoading(false);
      setCollectionItemsFull(resp);
    }
  };
  let bannerTimeZone = timezonesList?.find(
    (i: any) => collectionData?.timeZoneId == i?.id
  )?.abbrivation;
  let currencyName = currenciesList?.find(
    (i: any) => collectionData?.currency == i?.id
  )?.currencyCode;
  var userDetails = {} as any;
  const userD = localStorage.getItem("customerInfoDetails");
  if (userD != undefined && userD != null && userD != "") {
    userDetails = JSON.parse(userD);
  }
  const isClearableFn = () => {
    window.location.reload();
  };
  console.log("collectionData", collectionData);
  console.log("collectionItems", collectionItems);
  return (
    <StyledCollectionLotStatus>
      <div className="coll-lot-details-view-sec">
        {isLoading && <Loader />}
        <div className="coll-lot-details-view-sec-top">
          {collectionData?.title && (
            <>
              <div>
                <p className="collection-title-sec-lot">
                  <a href={`/collectionDetails/${collectionData?.id}`}>
                    <span>{"<"}</span> <span>{collectionData?.title}</span>
                  </a>
                </p>
                {/* <p>
                  <span>Start Date:</span>{" "}
                  <span>
                    {moment(collectionData?.onlineStartDate).format(
                      "MMMM D, YYYY"
                    )}{" "}
                    {moment(collectionData?.onlineStartTime, "HHmmss").format(
                      "h:mm A"
                    )}{" "}
                    {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}{" "}
                  </span>{" "}
                  | <span>Online Auction</span>
                </p> */}
              </div>
            </>
          )}
          <div>
            {ENV_SHOT_CODE === "prod" && (
              <div>
                <div className="collection-closses-time-all-sec1">
                  <div className="closses-time-all-sec-left1">
                    Saturday, September 23, 2023:
                  </div>
                  <div className="closses-time-all-sec-left2">
                    <p>9:00 am ET | Bidding Restarts</p>
                    <p>10:00 pm ET | Lots Begin Closing</p>
                  </div>
                  <div></div>
                  <div className="closses-time-all-sec-left3">
                    <p>
                      Lots close independently at one-minute intervals; closure
                      times are subject to change
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <h3>{collectionData?.title}</h3> */}
        </div>
        <div className="select-sec-box">
          <div className="select-sec-box1">
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={searchV ? searchV : "Select"}
              isSearchable={true}
              name="productName"
              id="productName"
              options={lotsList}
              onChange={(e: any) => {
                onChangeValueSelect(e);
              }}
              required
              value={searchV}
              placeholder={searchV ? searchV : "Select"}
            />
          </div>
          <div className="select-sec-box2" onClick={isClearableFn}>
            {" "}
            X
          </div>
        </div>
        <div className="all-lots-sec-content-sec grid-view-all">
          {collectionItems?.map((myLot: any) => {
            return (
              <>
                <LotBox
                  myLot={myLot}
                  bannerTimeZone={bannerTimeZone}
                  currencyName={currencyName}
                  userDetails={userDetails}
                  collectionData={collectionData}
                />
              </>
            );
          })}
        </div>
        {!isLoading &&
          !searchV &&
          collectionItemsFull?.totalCount > collectionItems?.length && (
            <div className="new-load-more-sec">
              <button onClick={handleViewMore}>LOAD MORE</button>
            </div>
          )}
      </div>
    </StyledCollectionLotStatus>
  );
};
export default CollectionLotStatus;
