import React from "react";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Accordion from "react-bootstrap/Accordion";
import { Card, Button } from "react-bootstrap";

import "./css/DescriptionTabs.css";

interface Props {
  lotData: any;
}
export const DescriptionTabs: React.FC<Props> = (Props) => {
  const { lotData } = Props;
  const createMarkup = (data: any) => {
    return { __html: data };
  };

  return (
    <>
      <div className="description_tabs_content">
        {/* <Tabs
          defaultActiveKey="Description"
          id="uncontrolled-tab-example"
          className="prd_des_list"
        >
          <Tab eventKey="Description" title="Description">
            <div className="des_tab_cont">
              <div>
                <div
                  dangerouslySetInnerHTML={createMarkup(lotData?.description)}
                />
              </div>
            </div>
          </Tab>
          {lotData?.conditionalReport && (
            <Tab eventKey="Condition_Report" title="Condition Report">
              <div className="des_tab_cont">
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    lotData?.conditionalReport
                  )}
                />
              </div>
            </Tab>
          )}
        </Tabs> */}
        <div className="tabs-content-section-view-main">Lot Details</div>
        <div className="tabs-content-section-view">Description</div>
        <div>
          <div dangerouslySetInnerHTML={createMarkup(lotData?.description)} />
        </div>
        <div className="tabs-content-section-view">Condition Report</div>
        {lotData?.conditionalReport && (
          <div className="des_tab_cont">
            <div
              dangerouslySetInnerHTML={createMarkup(lotData?.conditionalReport)}
            />
          </div>
        )}
        {lotData?.itemProvenances?.length > 0 && (
          <>
            <div className="tabs-content-section-view">Provenance</div>
            {lotData?.itemProvenances?.map((Itm: any) => {
              return <div>{Itm?.provenanceOwner}</div>;
            })}
          </>
        )}

        <div className="tabs-content-section-view">Other Details</div>
        <ul className="other-items-list-by-sec">
          {(lotData?.countryCode || true) && (
            <li>
              <div>
                <div className="other-item-key-val"> Country of Origin</div>{" "}
                <div className="other-item-collen-val">:</div>{" "}
                <div>{lotData?.countryCode}</div>{" "}
              </div>
            </li>
          )}
          {(lotData?.makerName || true) && (
            <li>
              <div>
                <div className="other-item-key-val">Artist/Designer</div>{" "}
                <div className="other-item-collen-val">:</div>{" "}
                <div>{lotData?.makerName}</div>{" "}
              </div>
            </li>
          )}
          {(lotData?.material || true) && (
            <li>
              <div>
                <div className="other-item-key-val">Medium/Materil</div>{" "}
                <div className="other-item-collen-val">:</div>{" "}
                <div>{lotData?.material}</div>{" "}
              </div>
            </li>
          )}
          {(lotData?.isRestrictedMaterial || true) && (
            <li>
              <div>
                <div className="other-item-key-val">Restricted materials</div>{" "}
                <div className="other-item-collen-val">:</div>{" "}
                <div>{lotData?.isRestrictedMaterial ? "Yes" : "No"}</div>{" "}
              </div>
            </li>
          )}
          {(lotData?.damages || true) && (
            <li>
              <div>
                <div className="other-item-key-val">Areas of Damange</div>{" "}
                <div className="other-item-collen-val">:</div>{" "}
                <div>{lotData?.damages} </div>{" "}
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
export default DescriptionTabs;
