import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Data } from "../../data/auction";
import { Container, Row, Col } from "react-bootstrap";

import TopBannerSlider from "../../components/TopBannerSlider";
import ActionCalendarLeftFilter from "../../components/ActionCalendarLeftFilter";
import ActionCalendarTabMenu from "../../components/ActionCalendarTabMenu";
import {
  getallupcommingauctionsCall,
  getAllAuctions,
  getPastAuctions,
} from "./../../library/Requests";
import { UPDATE_CALENDERDATA } from "./../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "./../../Scenes/common";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";

import "./css/AuctionCalendar.css";

class AuctionCalendar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tabKey: "all",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      tabMonths: [],
      currentPage: 1,
      jsonData: {},
      AllCollectionsFull: {},
      AllCollections: [],
      isLoading: false,
      isLoadingLoadMore: false,
      isResultsPage: false,
      errorMsg: false,
    };
    this.handleTab = this.handleTab.bind(this);
  }

  handleTab = (key: string) => {
    this.setState({ tabKey: key });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const body = {
      page: this.state.currentPage,
      pageSize: 18,
    };
    this.setState({ jsonData: body });
    console.log(this.props);
    if (this.props.location.pathname == "/auction-results") {
      //   this.setState({isResultsPage: true}, () => {this.getAllActions(body)});
      this.setState({ isResultsPage: true }, () => {
        this.getPastActions(body, "");
      });
      triggerMixpanelEvent("auction_results_page");
    } else {
      triggerMixpanelEvent("auction_calendar_page");
      this.getAllActions(body, "");
    }

    const mth: any = [];
    Data.map(
      (d) =>
        (mth[new Date(d.auctionStartDate).getUTCMonth()] = this.state.months[
          new Date(d.auctionStartDate).getUTCMonth()
        ])
    );
    this.setState({ tabMonths: Object.values(mth) });
    document.title = "FAHH";
  }
  updateMonthsDataFilter = () => {
    const { months, AllCollections } = this.state;
    const mth: any = [];
    (AllCollections || []).map((d: any) => {
      mth[moment(d?.auctionStartDate).format("M")] = moment(
        d?.auctionStartDate
      ).format("MMMM");
    });
    console.log("mth", mth);
    this.setState({ tabMonths: Object.values(mth), details: AllCollections });
  };
  getAllActions = async (body: any, type: any) => {
    const { isResultsPage } = this.state;
    if (type != "viewmore") {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoadingLoadMore: true });
    }
    if (isResultsPage) {
      // this.setState({isLoading:true})
      const resp1 = await getAllAuctions(body);
      this.setState({
        AllCollectionsFull: resp1,
        isLoading: false,
        isLoadingLoadMore: false,
      });
      let AllCollectionsP: any = [];
      AllCollectionsP = resp1?.pastAuctions;
      if (type == "viewmore") {
        this.setState({
          AllCollections: [...this.state.AllCollections, ...AllCollectionsP],
          isLoading: false,
        });
      } else {
        this.setState({ AllCollections: AllCollectionsP, isLoading: false });
      }
      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      this.updateMonthsDataFilter();
    } else {
      // this.setState({isLoading:true})
      body.SortColumn = "StartOnUtc";
      body.Sortorder = "asc";
      const resp = await getallupcommingauctionsCall(body);
      if (resp?.totalCount == 0) {
        // this.setState({ errorMsg: true });
      }
      this.setState({
        AllCollectionsFull: resp,
        isLoading: false,
        isLoadingLoadMore: false,
      });
      let AllCollectionsP: any = [];
      // if(resp?.onGoingAuctions?.length){
      //     let ongoing = resp?.onGoingAuctions;
      //     AllCollectionsP = ongoing.concat(resp?.upCommingAuctions);
      // }else{
      //     AllCollectionsP = resp?.auctions;
      // }
      AllCollectionsP = resp?.auctions;
      if (type == "viewmore") {
        this.setState({
          AllCollections: [...this.state.AllCollections, ...AllCollectionsP],
          isLoading: false,
        });
      } else {
        this.setState({ AllCollections: AllCollectionsP, isLoading: false });
      }
      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      this.updateMonthsDataFilter();
    }
  };
  clickViewMore = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1 });
    const body = this.state.jsonData;
    console.log("body", body);
    body.page = currentPage + 1;
    if (this.state.isResultsPage) {
      this.getPastActions(body, "viewmore");
    } else {
      this.getAllActions(body, "viewmore");
    }
  };

  getPastActions = async (body: any, type: any) => {
    const { isResultsPage } = this.state;
    // this.setState({isLoading: true});
    if (type != "viewmore") {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoadingLoadMore: true });
    }
    body.SortColumn = "EndOnUtc";
    body.Sortorder = "dsc";
    const resp1 = await getPastAuctions(body);
    if (resp1?.totalCount == 0) {
      // this.setState({ errorMsg: true });
    }
    this.setState({
      AllCollectionsFull: resp1,
      isLoading: false,
      isLoadingLoadMore: false,
    });

    if (type == "viewmore") {
      let AllCollectionsP: any = [];
      AllCollectionsP = resp1?.pastAuctions;

      this.setState({
        AllCollections: [...this.state.AllCollections, ...AllCollectionsP],
      });
      // this.setState({isLoading: false, isLoadingLoadMore: false});
      // console.log([...this.state.AllCollections,...AllCollectionsP]);
      // triggerEventForProfileUpdate(UPDATE_CALENDERDATA)
      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      this.updateMonthsDataFilter();
    } else {
      // this.setState({AllCollectionsFull:resp1});
      let AllCollectionsP: any = [];
      AllCollectionsP = resp1?.pastAuctions;
      this.setState({ AllCollections: AllCollectionsP });
      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      this.updateMonthsDataFilter();
    }
  };

  getUpdatedData = async (body: any) => {
    console.log(body);
    this.setState({ currentPage: 1 });
    this.setState({ jsonData: body });
    if (this.state.isResultsPage) {
      this.getPastActions(body, "");
    } else {
      this.getAllActions(body, "");
    }
  };
  render() {
    const {
      AllCollections,
      AllCollectionsFull,
      isLoading,
      isResultsPage,
      isLoadingLoadMore,
      errorMsg,
    } = this.state;
    // const thisMonth: string = this.state.months[new Date().getMonth()];
    return (
      <>
        {errorMsg ? (
          <p className="noResults noResults-auctionpafw" id="errorPage">
            No results are available in{" "}
            {isResultsPage ? "Auction Results" : "Auction Calendar"}
            {". "}
          </p>
        ) : (
          <div className="action_calendar_page_content">
            {!isResultsPage && <TopBannerSlider />}
            <div className="action_calendar_page_section">
              <Container>
                <Row>
                  <Col lg={3}>
                    <ActionCalendarLeftFilter
                      handleTab={this.handleTab}
                      tabMonths={this.state.tabMonths}
                      AllCollections={AllCollections}
                      getUpdatedDataCall={this.getUpdatedData}
                      AllCollectionsFull={AllCollectionsFull}
                      isResultsPage={isResultsPage}
                    />
                  </Col>
                  <Col lg={9}>
                    <ActionCalendarTabMenu
                      isLoading={isLoading}
                      isLoadingLoadMore={isLoadingLoadMore}
                      tabKey={this.state.tabKey}
                      handleTab={this.handleTab}
                      AllCollections={AllCollections}
                      AllCollectionsFull={AllCollectionsFull}
                      clickViewMore={this.clickViewMore}
                      isResultsPage={isResultsPage}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AuctionCalendar;
