import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Data } from "../../data/auction";

import ActionCalendarTabContent from "../../components/ActionCalendarTabContent";
import moment from "moment";
import "./css/ActionCalendarTabMenu.css";
import { UPDATE_CALENDERDATA } from "./../../library/Requests/eventConstants";

class ActionCalendarTabMenu extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      details: [],
      tabMonths: [],
      show: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  }

  componentDidMount() {
    this.updateStateData();
    const self = this;
    window.document.addEventListener(
      UPDATE_CALENDERDATA,
      function (event) {
        self.updateStateData();
      },
      false
    );
  }
  componentDidUpdate = () => {
    // this.updateStateData();
  };
  updateStateData = () => {
    const {
      tabKey,
      handleTab,
      AllCollections,
      AllCollectionsFull,
    } = this.props;
    const { months } = this.state;
    const mth: any = [];
    (AllCollections || []).map((d: any) => {
      mth[moment(d?.auctionStartDate).format("M")] = moment(
        d?.auctionStartDate
      ).format("MMMM");
    });
    console.log("mth", mth);
    this.setState({ tabMonths: Object.values(mth), details: AllCollections });
    // setTabMonths();
  };
  getList = (tabTitle: string) => {
    const { months } = this.state;
    const { tabKey, handleTab, AllCollections } = this.props;
    return AllCollections.filter((doc: any) => {
      let d: any = new Date(doc.auctionStartDate);
      return moment(doc.auctionStartDate).format("MMMM") === tabTitle;
    });
  };
  render() {
    const {
      tabKey,
      handleTab,
      AllCollections,
      AllCollectionsFull,
      clickViewMore,
      isLoadingLoadMore,
      isResultsPage,
    } = this.props;
    const { months, tabMonths, details } = this.state;
    const thisMonth: any = moment().format("MMMM");
    console.log("thisMonth", thisMonth);
    console.log("tabKey", tabKey);

    return (
      <>
        <div className="action_calendar_right_tab custom_action_calendar_tabs">
          <Tabs
            defaultActiveKey="all"
            activeKey={tabKey}
            onSelect={handleTab}
            id="uncontrolled-tab-example"
          >
            <Tab eventKey={"all"} title={"All"}>
              <div className="action_tab_content">
                {this.props?.isLoading ? (
                  <div className="spinner_Loading_icon">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <ActionCalendarTabContent
                    isLoadingLoadMore={isLoadingLoadMore}
                    isLoading={this.props?.isLoading}
                    viewMore={clickViewMore}
                    allCollFull={AllCollectionsFull}
                    selectedTab={"All"}
                    listDetailsNew={AllCollections}
                    listDetails={details}
                    isResultsPage={isResultsPage}
                    key={"all"}
                  />
                )}
              </div>
            </Tab>
            {tabMonths.map((tabTitle: any) => {
              console.log("tabTitle", tabTitle);
              return (
                <Tab
                  eventKey={tabTitle}
                  key={tabTitle}
                  title={thisMonth === tabTitle ? "This Month" : tabTitle}
                >
                  <div className="action_tab_content">
                    <ActionCalendarTabContent
                      isLoadingLoadMore={isLoadingLoadMore}
                      isResultsPage={isResultsPage}
                      isLoading={this.props?.isLoading}
                      viewMore={clickViewMore}
                      allCollFull={AllCollectionsFull}
                      selectedTab={
                        thisMonth === tabTitle ? "This Month" : tabTitle
                      }
                      listDetailsNew={AllCollections}
                      listDetails={this.getList(tabTitle)}
                      key={tabTitle}
                    />
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  }
}
export default ActionCalendarTabMenu;
