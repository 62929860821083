import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Col, Row, Button } from "react-bootstrap";

import Calend from "../../assets/image/calend.png";
import Share from "../../assets/image/share.png";
import Print from "../../assets/image/print.png";
import Download from "../../assets/image/download.png";
import ViewMore from "../../assets/image/view_more.png";
import { ReactComponent as InfoIcon } from "../../assets/image/infoIcon.svg";
import ShareModal from "../../components/ShareModal/ShareModal";
import { DOMAIN_URL } from "./../../config";

// import moment from 'moment';
import moment from "moment-timezone";
import { LOGINID } from "./../../Scenes/common";
import TopBannerTime from "./../../pages/collectionDetails/TopBannerTime";
import "./css/BrowseListTopBanner.css";
import { ENV_SHOT_CODE } from "./../../config";
// interface Props {
//   currenciesList: any;
//   timezonesList: any;
//   collectionData: any;
//   registertoBid?: any;
//   gotoAuctionCalenderPage?:any;
//   liveAuctionPage?: any;
//   showLiveActionBtn?:any;
//   biddingShowText?:any;
//   biddingShowNo?:any;
// }
// export const BrowseListTopBanner: React.FC<Props> = (Props) => {

class BrowseListTopBanner extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      bannerListOpen: false,
      shareOpen: false,
      updateStateData: false,
      // collectionData: {}
    };
  }
  componentDidMount = () => {
    // zz

    setInterval(() => {
      this.setState({ updateStateData: !this.state.updateStateData });
    }, 1000);
  };

  addToCalendar = () => {
    console.log("this.addToCalendar");
  };
  handleShareAction = (flag: any) => {
    console.log("flag", flag);
    this.setState({ shareOpen: flag });
  };
  render() {
    const {
      currenciesList,
      collectionData,
      registertoBid,
      showLiveActionBtn,
      biddingShowText,
      biddingShowNo,
      gotoAuctionCalenderPage,
      gotoAuctionResultsPage,
      liveAuctionPage,
      isPastAuction,
    } = this.props;
    let bannerTimeZone = this.props?.timezonesList?.find(
      (i: any) => collectionData?.timeZoneId == i?.id
    )?.abbrivation;

    const gmtDateTime = moment.utc(
      collectionData?.startOnUtc,
      "YYYY-MM-DD h:mm:ss a"
    );
    const local_date = gmtDateTime.local().format("MMMM D, YYYY");
    const local_time = gmtDateTime.local().format("h:mm A");
    const local_timezone = moment(collectionData?.startOnUtc)
      .tz(moment.tz.guess())
      .format("z");
    let endtimeLink = "";
    if (collectionData?.endOnUtc && collectionData?.endOnUtc != "") {
      const endtimeLink1 = moment.utc(
        collectionData?.endOnUtc,
        "YYYY-MM-DD h:mm:ss a"
      );
      endtimeLink = `${endtimeLink1
        .local()
        .format("YYYYMMDD")}T${endtimeLink1.local().format("HHMMSS")}`;
    }
    const timesLink = `${gmtDateTime
      .local()
      .format("YYYYMMDD")}T${gmtDateTime
      .local()
      .format("HHMMSS")}/${endtimeLink}`;
    const { bannerListOpen, shareOpen } = this.state;

    const showViewCollectionStatus = () => {
      var exactTime = collectionData?.liveEndDate;
      var newT = exactTime;
      if (exactTime && exactTime[exactTime?.length - 1] === "Z") {
        newT = exactTime?.substring(0, exactTime?.length - 1);
      }
      console.log("newT 111", newT);
      var currUTC = moment(newT).format("MM/DD/YYYY hh:mm:ss A UTC");
      const collTime = new Date(currUTC).getTime();
      console.log("collTimecollTimecollTime", collTime);
      const currTime = new Date().getTime();
      console.log("currTimecurrTimecurrTime", currTime);

      let falgV = false;
      if (biddingShowText === "Bidding Ends in:" && currTime > collTime) {
        falgV = true;
      }
      return falgV;
    };
    const showEndTimeSec = () => {
      var exactTime = collectionData?.liveEndDate;
      var newT = exactTime;
      if (exactTime && exactTime[exactTime?.length - 1] === "Z") {
        newT = exactTime?.substring(0, exactTime?.length - 1);
      }
      console.log("newT", newT);
      let falgV = false;
      var currUTC = moment(newT).format("MM/DD/YYYY hh:mm:ss A UTC");
      const collTime = new Date(currUTC).getTime();
      const currTime = new Date().getTime();

      if (biddingShowText === "Bidding Ends in:" && currTime < collTime) {
        falgV = true;
      }
      return falgV;
    };
    // console.log("shareOpen", shareOpen);
    const lotPageUrl = `${DOMAIN_URL}collectionDetails/${collectionData?.id}`;
    console.log("top banner top banner");
    return (
      <>
        <ShareModal
          show={shareOpen}
          handleClose={() => this.handleShareAction(false)}
          url={lotPageUrl}
          marketplaceId={collectionData?.id}
          title={collectionData?.title}
          onCopyClick={() => {
            navigator.clipboard.writeText(lotPageUrl);
            // setIsCopied(true);
            // setTimeout(() => {
            //   setIsCopied(false);
            // }, 5000);
          }}
        />

        <div className="brows_banner_content">
          <Container>
            <Row className="inner_banner_content">
              <Col lg={9}>
                <div className="brows_banner_left_content">
                  <div
                    className="brows_banner_back_btn d-inline-flex align-items-center"
                    onClick={() => {
                      isPastAuction
                        ? gotoAuctionResultsPage()
                        : gotoAuctionCalenderPage();
                    }}
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M7 13L1 7L7 1"
                        stroke="#111111"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {isPastAuction ? "Auction Results" : "Auction Calendar"}
                  </div>
                  <div className="auctions_details">
                    {collectionData?.title && (
                      <ul className="d-flex p-0">
                        <li className="strong position-relative">
                          {collectionData?.auctionType == "1" ? (
                            <>
                              {moment(collectionData?.onlineStartDate).format(
                                "MMMM D, YYYY"
                              )}{" "}
                            </>
                          ) : (
                            <>
                              {moment(collectionData?.liveStartDate).format(
                                "MMMM D, YYYY"
                              )}{" "}
                            </>
                          )}
                        </li>
                        <li className="position-relative">
                          {collectionData?.auctionType == "1" ? (
                            <>
                              {moment(
                                collectionData?.onlineStartTime,
                                "HHmmss"
                              ).format("h:mm A")}{" "}
                              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}{" "}
                            </>
                          ) : (
                            <>
                              {moment(
                                collectionData?.liveStartTime,
                                "HHmmss"
                              ).format("h:mm A")}{" "}
                              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}{" "}
                            </>
                          )}{" "}
                          <InfoIcon className="bannerInfoIcon" />
                          <div className="hoverInfo">
                            <p className="yourLocalTime">Your Local Time</p>
                            <p> {local_date} </p>
                            <p>
                              {" "}
                              {local_time} {local_timezone}{" "}
                            </p>
                          </div>
                        </li>

                        <li className="position-relative">
                          {collectionData?.auctionType == 1 ? "Online" : "Live"}{" "}
                          Auction
                        </li>
                      </ul>
                    )}
                    <h3>{collectionData?.title}</h3>
                    {/* {biddingShowText != "" &&
                      biddingShowNo != "" &&
                      biddingShowNo != "00 : 00 : 00" && (
                        <h6>
                          {biddingShowText}
                          <span className="font-weight-bold">
                            {" "}
                            {biddingShowNo}
                          </span>
                        </h6>
                      )} */}
                    {(biddingShowText != "" || isPastAuction) &&
                      biddingShowText !== "Bidding Ends in:" && (
                        <>
                          <TopBannerTime
                            collectionData={collectionData}
                            biddingShowText={biddingShowText}
                            isPastAuction={isPastAuction}
                          />
                        </>
                      )}
                    {showEndTimeSec() && ENV_SHOT_CODE === "qa" && (
                      <div className="collection-closses-time-all-sec">
                        <div className="collection-closses-time-all-sec-left">
                          Lot Closes:
                        </div>
                        <div className="collection-closses-time-all-sec-right">
                          <p>
                            {moment(collectionData?.onlineEndDate).format(
                              "MMMM D"
                            )}
                          </p>

                          <p>
                            {moment(
                              collectionData?.onlineEndTime,
                              "HHmmss"
                            ).format("h:mm A")}{" "}
                            {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}
                          </p>
                        </div>
                      </div>
                    )}
                    {ENV_SHOT_CODE === "prod" && (
                      <div className="collection-closses-time-all-sec1">
                        <div className="closses-time-all-sec-left1">
                          Saturday, September 23, 2023:
                        </div>
                        <div className="closses-time-all-sec-left2">
                          <p>9:00 am ET | Bidding Restarts</p>
                          <p>10:00 pm ET | Lots Begin Closing</p>
                        </div>
                        <div></div>
                        <div className="closses-time-all-sec-left3">
                          <p>
                            Lots close independently at one-minute intervals;
                            closure times are subject to change
                          </p>
                        </div>
                      </div>
                    )}
                    {/* {ENV_SHOT_CODE === "prod" && showViewCollectionStatus() && (
                      <p>
                        <a href={`/collectionLotStatus/${collectionData?.id}`}>
                          Click here to see Lot closing times for all Lots in
                          real-time
                        </a>
                      </p>
                    )}
                    {showViewCollectionStatus() && ENV_SHOT_CODE === "qa" && (
                      <p>
                        <a href={`/collectionLotStatus/${collectionData?.id}`}>
                          Click here to see Lot closing times for all Lots in
                          real-time
                        </a>
                      </p>
                    )} */}

                    {collectionData?.title && !isPastAuction && (
                      <>
                        {collectionData?.registredToCollection &&
                        biddingShowText !== "Bidding Ends in:" ? (
                          <Button
                            disabled
                            className="auction_btn_banner registeredbtns d-inline-block text-decoration-none"
                          >
                            REGISTERED
                          </Button>
                        ) : (
                          <>
                            {!isPastAuction &&
                              biddingShowText !== "Bidding Ends in:" && (
                                <>
                                  <Button
                                    onClick={registertoBid}
                                    className="auction_btn_banner registeredbtns onHoverRegister d-inline-block text-decoration-none"
                                  >
                                    REGISTER TO BID
                                  </Button>
                                </>
                              )}
                          </>
                        )}
                      </>
                    )}

                    {/* collectionData?.registredToCollection */}
                    {collectionData?.auctionType == 2 &&
                      showLiveActionBtn &&
                      collectionData.endOnUtc == null && (
                        <Button
                          onClick={liveAuctionPage}
                          className="auction_btn_banner registeredbtns d-inline-block text-decoration-none btn_spacing"
                        >
                          VIEW LIVE AUCTION
                        </Button>
                      )}
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div
                  className={`brows_banner_right_content show-srch ${
                    bannerListOpen ? "active" : ""
                  }`}
                >
                  <div
                    className="mobile_open_browse_list_background"
                    onClick={() => this.setState({ bannerListOpen: false })}
                  ></div>
                  <div
                    className="mobile_open_browse_list d-block d-lg-none"
                    onClick={() => this.setState({ bannerListOpen: false })}
                  >
                    {/* <div className="browse_banner_list d-flex align-items-center">
                      <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill border-0 bg-transparent">
                        <img src={ViewMore} className="position-absolute" />
                      </span>
                      <h5 className="mb-0">View more</h5>
                    </div> */}
                  </div>
                  <ul className="m-0 p-0">
                    <li>
                      <a
                        href={`https://www.google.com/calendar/event?action=TEMPLATE&text=${collectionData?.title}&dates=${timesLink}&details`}
                        className="browse_banner_list text-decoration-none d-flex align-items-center"
                      >
                        <Button
                          onClick={() => {
                            this.addToCalendar();
                          }}
                          className="browse_banner_list browse_banner_list_AddC text-decoration-none d-flex align-items-center"
                        >
                          <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                            <img src={Calend} className="position-absolute" />
                          </span>
                          <h5 className="mb-0">Add to Calendar</h5>
                        </Button>
                      </a>
                    </li>
                    <li>
                      <Link
                        to={`/collectionDetails/${collectionData?.id}`}
                        className="browse_banner_list text-decoration-none d-flex align-items-center"
                      >
                        <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                          <img
                            onClick={() => {
                              this.handleShareAction(true);
                            }}
                            src={Share}
                            className="icon_share position-absolute"
                          />
                        </span>
                        <h5
                          onClick={() => {
                            this.handleShareAction(true);
                          }}
                          className="mb-0"
                        >
                          Share
                        </h5>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/collectionDataPrint/${collectionData?.id}`}
                        className="browse_banner_list text-decoration-none d-flex align-items-center"
                      >
                        <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                          <img
                            src={Print}
                            className="icon_share position-absolute"
                          />
                        </span>
                        <h5 className="mb-0">Print</h5>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/collectionDataDownload/${collectionData?.id}`}
                        target="_blank"
                        className="browse_banner_list text-decoration-none d-flex align-items-center"
                      >
                        <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                          <img
                            src={Download}
                            className="icon_share position-absolute"
                          />
                        </span>
                        <h5 className="mb-0">Download Auction Catalog (PDF)</h5>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default BrowseListTopBanner;
