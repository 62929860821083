import React, { Component } from "react";
import { Link } from "react-router-dom";

import Breadcrumb from "react-bootstrap/Breadcrumb";

import Art1 from "../../assets/image/ar1.png";
import Art2 from "../../assets/image/ar2.png";
import Currency from "../../assets/image/currency.png";
import BidWhite from "../../assets/image/bid-white.png";
import infoIconIcon from "../../assets/image/infoIcon.svg";
import { Button, Modal } from "react-bootstrap";
import ImageGallerySlider from "../../components/ImageGallerySlider";

import DescriptionTabs from "../../components/DescriptionTabs";

import MoreCollectionColum from "../../components/MoreCollectionColum";
import BidIncrementTable from "../../components/BidIncrementTable";
import CurrencyConverter from "../../components/CurrencyConverter";
// import moment from 'moment';
import moment from "moment-timezone";
import WordBanner from "./../../assets/image/word_banner.jpg";
import slide1 from "./../../assets/image/slide1.jpg";

import { LOGINID } from "./../../Scenes/common";
// import "./css/bid1.css";
import LoginPopup from "../../components/LoginPopup";
import "bootstrap/dist/css/bootstrap.min.css";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";

import { AFTER_LOGIN_TRIGGER_PAGE } from "./../../library/Requests/eventConstants";

import {
  getcollectionitembyidCall,
  getItemidsbyCollectionIdCall,
  getbidincrementCall,
  getCollectionItemsCall,
  getTimeZones,
  gethighestbid,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  unfollowbidCall,
} from "./../../library/Requests";

import ContentLoader from "react-content-loader";
import { isMobile } from "react-device-detect";

import * as signalR from "@microsoft/signalr";

import { SERVER_IP } from "./../../library/constants.js";

class lotDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      timeZones: [],
      collectionData: {},
      lotData: [],
      imagesList: [],
      collectionItems: [],

      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showVideo: {},
      prevLot: {},
      nextLot: {},
      highestBidDetails: {},
      showPlaceBidBtn: false,
      // loginPopupShow: false,
      isLoading: false,
      lotStatusText: "",
      ShowPopCurrency: false,
      ShowBidIncrementTable: false,
      bidIncrementTableData: [],
      allItemsData: [],
      errorMsg: false,
    };
  }

  componentDidMount() {
    const id = this.props?.match?.params?.id;
    this.getcurrenciesDataCall();
    this.getLotData(id, "");
    this.getHighestBidDetails(id);
    const self = this;
    window.document.addEventListener(
      AFTER_LOGIN_TRIGGER_PAGE,
      function (event) {
        self.triggerFunctionCall();
      },
      false
    );

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .withAutomaticReconnect()
      .build();
    connection.on("BidInsertSignal", (response) => {
      console.log(
        "We got signal! and the message is: " + JSON.stringify(response)
      );
      // Bind GetHightestBids here
      // alert(response);
    });
    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          console.log(
            "We got signal! and the message is: " + JSON.stringify(response)
          );
          console.log(response);
          // Bind GetHightestBids here
          self.callgetHighestBidNew(response);
        });
      })
      .catch((err) => console.error(err.toString()));
  }
  callgetHighestBidNew = async (response: any) => {
    console.log("responseresponse", response);
    console.log("5555555");
    const id = this.props?.match?.params?.id;
    if (response?.itemId == id) {
      this.getLotData(id, "autoUpdate");
      this.getHighestBidDetails(id);
    }
  };
  getHighestBidDetails = async (id: any) => {
    const bidResp = await gethighestbid(id);
    this.setState({ highestBidDetails: bidResp?.bidDetails });
    if (this.state.bidIncrementTableData.length < 10) {
      const bidDataResp = await getbidincrementCall();
      this.setState({ bidIncrementTableData: bidDataResp?.increment });
    }
    // console.log("bidDataResp__",bidDataResp)
  };
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
    const timeZoneResp = await getTimeZones();
    this.setState({ timeZones: timeZoneResp.data });
  };
  getCollectionItems = async (id: any) => {
    const body = {
      page: 1,
      pageSize: 50,
      collectionId: id,
    };
    let resp = await getCollectionItemsCall(body);
    let listItems = resp?.data;
    const index = listItems.findIndex(
      (item: any) => item?.id === this.state.lotData?.id
    );
    if (index > -1) {
      listItems.splice(index, 1);
    }
    this.setState({ collectionItems: listItems });
    // this.getPrevNextItems(resp?.data);
  };
  getallitemsData = async (collId: any) => {
    if (collId != undefined && collId != null && collId) {
      let resp1 = await getItemidsbyCollectionIdCall(collId);
      this.setState({ allItemsData: resp1?.data });
      this.getPrevNextItems(resp1?.data);
    }
  };
  getLotData = async (id: any, updateType: any) => {
    if (updateType != "autoUpdate") {
      window.scrollTo(0, 0);
      this.setState({ isLoading: true });
    }
    const collectionItem = await getcollectionitembyidCall(id, LOGINID);
    this.setState({ isLoading: false });
    this.getCollectionData(collectionItem?.data?.collectionId);
    this.setState({ lotData: collectionItem?.data });
    this.getCollectionItems(collectionItem?.data?.collectionId);
    this.getallitemsData(collectionItem?.data?.collectionId);
    let imageL = collectionItem?.data?.itemImages;
    if (collectionItem?.data?.id == 0) {
      this.setState({ errorMsg: true });
      document.title = "FAHH";
    } else {
      document.title = this.state.lotData?.title;
    }

    let imageList: any = [];
    imageL?.map((image: any, index: any) => {
      const imageP = image?.vurtualPath;
      let imageT = image?.vurtualPath;
      if (image.displayOrder == 8) {
        imageT = imageL[0].vurtualPath;
        var temp1 = {
          original: imageT,
          thumbnail: imageT,
          embedUrl: image?.vurtualPath,
          renderItem: this._renderVideo.bind(this),
        };
        imageList.push(temp1);
      } else {
        var temp = { original: imageP, thumbnail: imageT };
        imageList.push(temp);
      }
    });
    this.setState({ imagesList: imageList });
  };

  getPrevNextItems = (list: any) => {
    const { lotData } = this.state;
    (list || []).map((item: any, index: any) => {
      if (lotData.id == item.itemId) {
        let currentNoo = item.rno;
        const prevIn = currentNoo - 1;
        const nectIn = currentNoo + 1;
        let prevLotN = list.filter((i2: any) => i2.rno == prevIn);
        let nectLotN = list.filter((i2: any) => i2.rno == nectIn);
        if (prevLotN.length > 0) {
          this.setState({ prevLot: prevLotN[0] });
        } else {
          this.setState({ prevLot: {} });
        }
        if (nectLotN.length > 0) {
          this.setState({ nextLot: nectLotN[0] });
        } else {
          this.setState({ nextLot: {} });
        }
      }
    });

    // (list || []).map((item:any,index:any)=>{
    //   if(lotData.sno==item.sno){
    //     const prevIn = index-1;
    //     const nectIn = index+1;
    //     if(list[prevIn]?.sno  && list[prevIn].id){
    //       this.setState({prevLot:list[prevIn] })
    //     }else{
    //       this.setState({prevLot:{}})
    //     }
    //     if(list[nectIn]?.sno  && list[nectIn].id){
    //       this.setState({nextLot:list[nectIn] })
    //     }else{
    //       this.setState({nextLot:{}})
    //     }
    //   }
    // })
  };
  gotoNextLot = (url: any, id: any) => {
    this.props.history.push(url);
    this.getcurrenciesDataCall();
    this.getLotData(id, "");
    this.getHighestBidDetails(id);
  };
  followBid = async (id: any) => {
    this.props.afterLoginTriggerPageCall({});
    const { lotData } = this.state;
    // const index = collectionItems.findIndex((item:any) => item?.id === data?.id);
    // if (index > -1) {
    //   collectionItems[index].ifollowed = true;
    // }
    // console.log(id);
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        lotData.ifollowed = true;
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: true,
          },
        };
        this.setState({ lotData: lotData });
        const resp = await followbidCall(body);
      }
    } else {
      // localStorage.setItem("afterLoginTriggerPage", "lotDetailsPage__"+id)
      const jsonD = {
        pageName: "followClick",
        lotId: id,
      };
      this.props.afterLoginTriggerPageCall(jsonD);
      this.props.LopinPopUpPage(true);
    }
  };
  triggerFunctionCall = () => {
    // console.log(this.props?.afterLoginTriggerPage);
    if (
      this.props?.afterLoginTriggerPage &&
      this.props?.afterLoginTriggerPage?.lotId
    ) {
      this.followBid(this.props?.afterLoginTriggerPage?.lotId);
    }
  };
  unFollowBid = async (id: any) => {
    const { lotData } = this.state;
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: false,
          },
        };
        lotData.ifollowed = false;
        this.setState({ lotData: lotData });
        const resp = await followbidCall(body);
      }
    }
  };

  _toggleShowVideo(url: any) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  ShowCurrencyModal() {
    this.setState({ ShowPopCurrency: true });
  }
  ShowBidIncrementModal() {
    this.setState({ ShowBidIncrementTable: true });
  }
  howBidWorkCall() {
    this.props.history.push(`/howBiddingWorks/${this.state.lotData?.id}`);
    window.scrollTo(0, 0);
  }

  _renderVideo(item: any) {
    return (
      <div>
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            <iframe
              width="560"
              height="315"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button"></div>
            <img className="image-gallery-image" src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }
  gotoBigPage = (url: any, id: any) => {
    this.props.history.push(url);
    this.getcurrenciesDataCall();
    this.getLotData(id, "");
  };
  getCollectionData = async (id: any) => {
    const collectionItem = await getCollectionByIdCall(id);
    this.setState({ collectionData: collectionItem?.data });
    this.showPlaceBidBtnCheck();
  };
  hideModal = () => {
    this.setState({ ShowBidIncrementTable: false });
    this.setState({ ShowPopCurrency: false });
  };
  // showDateStatus = ()=>{
  //   const {collectionData} = this.state;
  //   if(collectionData?.biddingStartDateOnUTC != undefined && collectionData.auctionType == 2){
  //     var liveCurrUTC = moment(collectionData?.biddingStartDateOnUTC).format("MM/DD/YYYY hh:mm:ss A");
  //       const startTime = new Date(liveCurrUTC).getTime();
  //       const currTime = new Date().getTime();
  //       if(currTime > startTime){
  //         return "Lot Starts At";
  //       }
  //   }else if()

  // }
  showPlaceBidBtnCheck = () => {
    const { collectionData } = this.state;
    console.log("collectionData", collectionData);
    if (
      collectionData?.biddingStartDateOnUTC != undefined &&
      collectionData.auctionType == 2
    ) {
      // var currUTC = moment(collectionData?.biddingStartDateOnUTC).format("MM/DD/YYYY hh:mm:ss A UTC");
      // var currUTC1 = moment(collectionData?.biddingStartDateOnUTC).format("MM/DD/YYYY hh:mm:ss A UTC").getTime();
      // const collTime = new Date(currUTC).getTime();
      const gmtDateTime = moment.utc(
        collectionData?.biddingStartDateOnUTC,
        "YYYY-MM-DD h:mm:ss a"
      );
      const local_date = gmtDateTime.local().format("MM/DD/YYYY hh:mm:ss A");
      const collTime333 = new Date(local_date).getTime();
      // console.log("local_date",collTime333);

      const currTime = new Date().getTime();
      if (currTime > collTime333) {
        this.setState({ showPlaceBidBtn: true });
        // this.setState({});
        // document.getElementById("dateStatus").innerHTML=
        // document.getElementById("demo").innerHTML = "Lot Starts At";
      }
      if (currTime < collTime333) {
        this.setState({ lotStatusText: "Lot Starts At" });
      }
      const gmtDateTime1 = moment.utc(
        collectionData?.startOnUtc,
        "YYYY-MM-DD h:mm:ss a"
      );
      const local_date1 = gmtDateTime1.local().format("MM/DD/YYYY hh:mm:ss A");
      const collTime3331 = new Date(local_date1).getTime();

      // var currUTCStart = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
      // const collStartTime = new Date(currUTCStart).getTime();
      if (currTime > collTime3331) {
        this.setState({ showPlaceBidBtn: false });
      }
    } else if (collectionData?.auctionType == 1) {
      if (collectionData?.startOnUtc != undefined) {
        // var fullDT1 = dd.substr(0, 11)+tt;
        // var currUTC = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
        // const collTime1 = new Date(currUTC).getTime();
        const gmtDateTime = moment.utc(
          collectionData?.startOnUtc,
          "YYYY-MM-DD h:mm:ss a"
        );
        const local_date = gmtDateTime.local().format("MM/DD/YYYY hh:mm:ss A");
        const collTime1 = new Date(local_date).getTime();

        const currTime1 = new Date().getTime();
        if (currTime1 < collTime1) {
          this.setState({ lotStatusText: "Lot Starts At" });
        } else if (collectionData?.endOnUtc != undefined) {
          // var fullDT2 = ddd.substr(0, 11)+ttt;
          // var startCurrUTC = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
          //   var currUTC = moment(collectionData?.endOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
          //   const startCollTime2 = new Date(startCurrUTC).getTime();
          //   const collTime2 = new Date(currUTC).getTime();

          const gmtDateTime1 = moment.utc(
            collectionData?.startOnUtc,
            "YYYY-MM-DD h:mm:ss a"
          );
          const local_date1 = gmtDateTime1
            .local()
            .format("MM/DD/YYYY hh:mm:ss A");
          const startCollTime2 = new Date(local_date1).getTime();

          const gmtDateTime2 = moment.utc(
            collectionData?.endOnUtc,
            "YYYY-MM-DD h:mm:ss a"
          );
          const local_date2 = gmtDateTime2
            .local()
            .format("MM/DD/YYYY hh:mm:ss A");
          const collTime2 = new Date(local_date2).getTime();

          const currTime2 = new Date().getTime();
          if (startCollTime2 < currTime2 && currTime2 < collTime2) {
            // console.log("endOnUtcendOnUtc4444");
            this.setState({
              lotStatusText: "Lot Closes",
              showPlaceBidBtn: true,
            });
          } else if (currTime2 < collTime2) {
            // console.log("endOnUtcendOnUtc66666");
            this.setState({
              showPlaceBidBtn: true,
              lotStatusText: "Lot Closed At",
            });
          } else if (currTime2 > collTime2) {
            this.setState({ lotStatusText: "Lot Closed:" });
          }
        }
      }
    }
  };
  render() {
    const {
      collectionData,
      currenciesList,
      lotData,
      collectionItems,
      imagesList,
      timeZones,
      prevLot,
      nextLot,
      highestBidDetails,
      showPlaceBidBtn,
      isLoading,
      lotStatusText,
      errorMsg,
    } = this.state;

    let currencyName = currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;
    let bannerTimeZone = timeZones.find(
      (i: any) => collectionData?.timeZoneId === i?.id
    )?.abbrivation;
    if (!bannerTimeZone) {
      bannerTimeZone = timeZones.find(
        (i: any) => collectionData?.timeZoneId === i?.id
      )?.name;
    }

    return (
      <>
        {" "}
        {errorMsg ? (
          <p className="noResults" id="errorPage">
            No Results are Available in Lot Details !{" "}
          </p>
        ) : (
          <>
            {isLoading ? (
              <>
                <div className="spinner_Loading_icon loaderClassforLotDetails">
                  {/* <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div> */}
                  <ContentLoader viewBox="0 0 800 400" height={400} width={800}>
                    <rect
                      x="470"
                      y="104"
                      rx="5"
                      ry="5"
                      width="220"
                      height="10"
                    />
                    <rect
                      x="470"
                      y="124"
                      rx="5"
                      ry="5"
                      width="220"
                      height="10"
                    />
                    <rect
                      x="470"
                      y="144"
                      rx="5"
                      ry="5"
                      width="220"
                      height="10"
                    />
                    <rect
                      x="64"
                      y="18"
                      rx="0"
                      ry="0"
                      width="346"
                      height="200"
                    />
                    <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
                    <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
                    <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
                    <rect
                      x="470"
                      y="18"
                      rx="0"
                      ry="0"
                      width="300"
                      height="25"
                    />
                    <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
                    <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
                    <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
                    <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
                    <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
                    <rect
                      x="470"
                      y="179"
                      rx="0"
                      ry="0"
                      width="70"
                      height="30"
                    />
                    <rect
                      x="560"
                      y="179"
                      rx="0"
                      ry="0"
                      width="70"
                      height="30"
                    />
                    <rect
                      x="112"
                      y="250"
                      rx="5"
                      ry="5"
                      width="60"
                      height="60"
                    />
                    <rect
                      x="198"
                      y="250"
                      rx="5"
                      ry="5"
                      width="60"
                      height="60"
                    />
                    <rect
                      x="295"
                      y="250"
                      rx="5"
                      ry="5"
                      width="60"
                      height="60"
                    />
                    <rect
                      x="70"
                      y="340"
                      rx="0"
                      ry="0"
                      width="131"
                      height="12"
                    />
                    <rect
                      x="70"
                      y="370"
                      rx="0"
                      ry="0"
                      width="566"
                      height="12"
                    />
                    <rect
                      x="70"
                      y="390"
                      rx="0"
                      ry="0"
                      width="566"
                      height="12"
                    />
                  </ContentLoader>
                </div>
              </>
            ) : (
              <>
                <div className="inner_banner_slider position-relative">
                  <div className="banner_left">
                    <div className="banner_breadcrumb breadcrumb_hide_mobile d-none d-lg-flex align-items-center justify-content-between">
                      <div className="breadcrumb_left d-flex align-items-center">
                        <Link
                          to={`/collectionDetails/${lotData?.collectionId}`}
                          className="breadcumb_btn text-decoration-none"
                        >
                          <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                          >
                            <path
                              d="M7 13L1 7L7 1"
                              stroke="#111111"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          {collectionData?.title}
                        </Link>
                      </div>
                      <div className="breadcrumb_right d-flex align-items-center">
                        {prevLot?.itemId && (
                          <Button
                            onClick={() => {
                              this.gotoNextLot(
                                `/lotDetails/${prevLot?.itemId}`,
                                prevLot?.itemId
                              );
                            }}
                            className=" bgCOlorforBtn breadcumb_btn d-flex align-items-center text-decoration-none"
                          >
                            Lot {prevLot?.sno}{" "}
                            <img src={Art2} className="mx-2" />
                          </Button>
                        )}
                        {nextLot?.itemId && (
                          <>
                            <Button
                              onClick={() => {
                                this.gotoNextLot(
                                  `/lotDetails/${nextLot?.itemId}`,
                                  nextLot?.itemId
                                );
                              }}
                              className="bgCOlorforBtn breadcumb_btn d-flex align-items-center text-decoration-none"
                            >
                              <img src={Art1} className="mx-2" /> Lot{" "}
                              {nextLot?.sno}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <Breadcrumb className="d-lg-none d-block custom_breadcrumb">
                      <Breadcrumb.Item
                        href="#"
                        className="text-decoration-none"
                      >
                        The Collection of ...
                      </Breadcrumb.Item>
                      <Breadcrumb.Item active>Lot 1</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="banner_for_main">
                      {/* <div className="lot_no d-inline-block bg-white mb-2">
                        Lot {lotData?.sno}
                      </div> */}
                      <ImageGallerySlider images={imagesList} />
                    </div>
                  </div>
                  <div className="banner_right_cont">
                    <div className="right_cont_details_box bg-white">
                      <h4>{lotData?.title} </h4>

                      {collectionData?.onlineEndDate ||
                      (!collectionData?.endOnUtc &&
                        collectionData?.auctionType == 2 &&
                        lotStatusText == "Lot Starts At") ? (
                        <>
                          <div className="lots_cost">
                            <span
                              id="dateStatus"
                              className="d-block lotDetailsTextRightSide "
                            >
                              {lotStatusText}
                            </span>
                            <span className="strong d-block ">
                              {lotStatusText == "Lot Starts At" ? (
                                <>
                                  {collectionData?.auctionType == 2 ? (
                                    <>
                                      {moment(
                                        collectionData?.liveStartDate
                                      ).format("DD MMMM")}{" "}
                                      ,{" "}
                                      {moment(
                                        collectionData?.liveStartTime,
                                        "HHmmss"
                                      ).format("h:mm A")}
                                    </>
                                  ) : (
                                    <>
                                      {moment(
                                        collectionData?.onlineStartDate
                                      ).format("DD MMMM")}{" "}
                                      ,{" "}
                                      {moment(
                                        collectionData?.onlineStartTime,
                                        "HHmmss"
                                      ).format("h:mm A")}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* {moment(collectionData?.onlineEndDate).format("Do MMM")} , {moment(collectionData?.onlineEndTime, 'HHmmss').format("h:mm a")} */}
                                  {moment(lotData?.expiresOn).format(
                                    "DD MMMMM h:mm a"
                                  )}
                                </>
                              )}
                              ({bannerTimeZone})
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          {collectionData?.auctionType == 2 &&
                          collectionData?.endOnUtc ? (
                            <>
                              <div className="lots_cost">
                                <span
                                  id="dateStatus"
                                  className="d-block lotDetailsTextRightSide "
                                >
                                  Lot Closed:
                                </span>
                                <span className="strong d-block ">
                                  {moment(collectionData?.endOnUtc).format(
                                    "DD MMMM"
                                  )}{" "}
                                  ,{" "}
                                  {moment(
                                    collectionData?.endOnUtc,
                                    "HHmmss"
                                  ).format("h:mm A")}
                                  ({bannerTimeZone})
                                </span>
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                      <div className="lots_cost">
                        <span className="d-block lotDetailsTextRightSide">
                          Estimate
                        </span>
                        <span className="strong d-block ">
                          {currencyName} {lotData?.estimatedPrice}
                          {lotData?.estimatedPriceTo && (
                            <>
                              - {currencyName} {lotData?.estimatedPriceTo}
                            </>
                          )}
                        </span>
                      </div>
                      <div className="lots_cost lots_cost_lat">
                        {lotData?.status === 6 ? (
                          <>
                            <span className="d-block lotDetailsTextRightSide">
                              Lot Sold:
                            </span>
                            <span className="strong d-block">
                              {currencyName} {lotData?.soldFor}
                            </span>
                          </>
                        ) : lotData?.status === 7 ? (
                          <span className="d-block lotDetailsTextRightSide">
                            Bidding is Closed
                          </span>
                        ) : (
                          <>
                            <span className="d-block lotDetailsTextRightSide">
                              Current Bid: ({lotData?.bidsMetReservedPriceCount}{" "}
                              Bids, reserve met)
                            </span>
                            <span className="strong d-block ">
                              {currencyName} {highestBidDetails?.amount}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="btn_lots d-flex align-items-center">
                        {lotData?.showPlaceBid && (
                          <div className="pr-2 w-50">
                            <Link
                              to={`/placeBid/${lotData?.id}`}
                              className="lots_btn_same lots_btn_same_placeBid bid-white text-white text-decoration-none text-center d-inline-block w-100"
                            >
                              <img src={BidWhite} className="bid_icon" /> PLACE
                              BID
                            </Link>
                          </div>
                        )}
                        <div className="pl-2 w-50">
                          {lotData?.ifollowed ? (
                            <Button
                              onClick={() => {
                                this.unFollowBid(lotData?.id);
                              }}
                              className=" followingThisBid lots_btn_same_placeBid lots_btn_same text-decoration-none text-center d-inline-block w-100"
                            >
                              FOLLOWING
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                this?.followBid(lotData?.id);
                              }}
                              className="lots_btn_same lots_btn_same_placeBid text-decoration-none bg-white text-center d-inline-block w-100"
                            >
                              FOLLOW
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="cur_convt d-flex align-items-center justify-content-end">
                      <Button
                        className="CurrencyConverterBtn"
                        onClick={() => this.ShowCurrencyModal()}
                      >
                        <img src={Currency} /> Currency Converter
                      </Button>
                    </div> */}
                    <div className="bidding-table-info-view-sec">
                      <div className="cur_convt d-flex align-items-center bidding-table-info-view-sec-left justify-content-end">
                        <p
                          className="BidIncrementTableText"
                          onClick={() => this.howBidWorkCall()}
                        >
                          {" "}
                          <span className="InfoIconSpan">
                            <img src={infoIconIcon} width="20" height="20" />
                          </span>
                          How Bidding Works
                        </p>
                      </div>
                      <div className="cur_convt d-flex align-items-center justify-content-end">
                        <p
                          className="BidIncrementTableText"
                          onClick={() => this.ShowBidIncrementModal()}
                        >
                          <span className="InfoIconSpan">
                            <img src={infoIconIcon} width="20" height="20" />
                          </span>
                          Bid Increments Table
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <CurrencyConverter
                  ShowPopCurrency={this.state.ShowPopCurrency}
                  hideModal={this.hideModal}
                />

                <BidIncrementTable
                  ShowBidIncrementTable={this.state.ShowBidIncrementTable}
                  hideModal={this.hideModal}
                  bidIncrementTableData={this.state.bidIncrementTableData}
                  currencyName={currencyName}
                />

                <div className="product_description">
                  <DescriptionTabs lotData={lotData} />
                </div>
                <MoreCollectionColum
                  collectionItems={collectionItems}
                  currenciesList={currenciesList}
                  collectionData={collectionData}
                  gotoBigPage={this.gotoBigPage}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}
// export default lotDetails;
const mapStateToProps = (state: any) => {
  return state;
};
// export default withRouter(LoginPopup);
export default connect(mapStateToProps, actionCreators)(lotDetails);
