import * as React from "react";

import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

import Form from "react-bootstrap/Form";

import "./css/bid2.css";

import BidsImg from "../../assets/image/slide1.jpg";

import Currency from "../../assets/image/currency1.png";
import BidWhite from "../../assets/image/bid-white.png";
import BidBlack from "../../assets/image/bid-black.png";
import InfoIcon from "../../assets/image/icon3.png";
import CurrencyConverterBox from "./../lotDetails1/CurrencyConverterBox";
import PrevBidDetails from "../../components/PrevBidDetails/PrevBidDetails";
// import moment from 'moment';
import moment from "moment-timezone";
import WordBanner from "./../../assets/image/word_banner.jpg";

import { LOGINID } from "./../../Scenes/common";
import {
  getcollectionitembyidCall,
  getlivebidsCall,
  getbidincrementCall,
  gethighestbid,
  getCollectionItemsCall,
  getTimeZones,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  unfollowbidCall,
} from "./../../library/Requests";

import {
  autobidCallBroadCast,
  quickbidCallBroadCast,
} from "./../../library/Requests/PlaceBid";
import CustomModal from "./../../components/CustomModel";
import BidIncrementTable from "./../../components/BidIncrementTable";
import CurrencyConverter from "./../../components/CurrencyConverter";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";

import { AFTER_PLACEBID_TRIGGER_PAGE } from "./../../library/Requests/eventConstants";

import { HubConnectionBuilder } from "@microsoft/signalr";
// import { HubConnection } from 'signalr-client-react';
// import "" from ""
import * as signalR from "@microsoft/signalr";

import { SERVER_IP } from "./../../library/constants.js";
import ContentLoader from "react-content-loader";
import Loader from "./../../components/Common/Loader";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import { isMobile } from "react-device-detect";

class PlaceBid extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      timeZones: [],
      collectionData: {},
      lotData: [],
      imagesList: [],
      collectionItems: [],
      autoBidSuccessPlaced: false,
      autoBidSuccessPlacedSuccess: false,
      autoBidSuccessText: "Your Bid has been successfully placed.",
      maximumBid: "",
      maximumBid1: "",
      highestBidDetails: {},
      highestBidResp: {},
      bidIncrementData: [],
      showBiddingTie: "",
      bidStartEndType: "Starts",
      ShowBidIncrementTable: false,
      ShowPopCurrency: false,
      isPastAuction: false,
      showPlaceBidBtn: false,
      isLoading: false,
      pageLoading: false,
      selectedCurrency: {},
      showPrevBids: false,
      lotFinalStatus: "",
      showAlertMessageAfterCloses: false,
    };
  }

  componentDidMount() {
    localStorage.removeItem("place_bid_newInterValues");
    window.scrollTo(0, 0);
    this.getcurrenciesDataCall();
    this.getLotData("onLoad");
    const self = this;
    const lotIdid = this.props?.match?.params?.id;
    triggerMixpanelEvent("place_bid_page", { lotId: lotIdid });

    window.document.addEventListener(
      AFTER_PLACEBID_TRIGGER_PAGE,
      function (event) {
        self.PlaceAutoBid();
      },
      false
    );

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .withAutomaticReconnect()
      .build();
    connection.on("BidInsertSignal", (response: any) => {
      console.log(
        "We got signal! and the message is: " + JSON.stringify(response)
      );
      // Bind GetHightestBids here
      // alert(response);
    });
    connection
      .start()
      .then((result: any) => {
        connection.on("BidInsertSignal", (response: any) => {
          console.log(
            "We got signal! and the message is: " + JSON.stringify(response)
          );
          console.log(response);
          // Bind GetHightestBids here
          self.callgetHighestBid(response);
        });
      })
      .catch((err: any) => console.error(err.toString()));

    // const connection3 = new signalR.HubConnectionBuilder()
    //     .withUrl(`${SERVER_IP}/hubs/BidHub`)
    //     .build();
    //     connection3.on("BidingBotSignal", (response) => {
    //   console.log("We got signal! and the message is: " + response);
    //   // Bind GetHightestBids here
    //   // alert(response);
    // });
    // connection3.start().then(result => {
    //   connection3.on("BidingBotSignal", (response) => {
    //     console.log("We got signal! and the message is: " + response);
    //     console.log(response);
    //     // Bind GetHightestBids here
    //     self.callgetHighestBid(response);
    //   });
    // }).catch(err => console.error(err.toString()));
  }
  callgetHighestBid = async (response: any) => {
    // console.log("responseresponse", response);
    // console.log("5555555");
    const id = this.props?.match?.params?.id;
    if (response?.itemId == id) {
      this.getHighestBidDetails(id);
      this.getCollectionItemDataCall(id);
      // getCollectionData
    }
  };
  handleShowCurrency = (itttM: any) => {
    this.setState({ selectedCurrency: itttM });
  };

  getCollectionItemDataCall = async (id: any) => {
    const collectionItem = await getcollectionitembyidCall(id, LOGINID);
    this.setState({ lotData: collectionItem?.data });
    this.getCollectionData(collectionItem?.data?.collectionId, "");
  };

  // getLiveBid = async (id:any)=>{
  //   const resp = await getlivebidsCall(id);
  // }

  ShowCurrencyModal() {
    this.setState({ ShowPopCurrency: true });
  }

  ShowBidIncrementModal() {
    this.setState({ ShowBidIncrementTable: true });
  }
  hideModal = () => {
    this.setState({ ShowBidIncrementTable: false });
    this.setState({ ShowPopCurrency: false, showPrevBids: false });
  };
  getHighestBidDetails = async (id: any) => {
    const bidResp = await gethighestbid(id);
    this.setState({
      highestBidDetails: bidResp?.bidDetails,
      highestBidResp: bidResp,
    });
    if (this.state.bidIncrementData.length < 10) {
      const jsonVal = await getbidincrementCall();
      this.setState({ bidIncrementData: jsonVal?.increment });
      this.generateDropDownvalues(bidResp?.bidDetails, jsonVal?.increment);
    } else {
      this.generateDropDownvalues(
        bidResp?.bidDetails,
        this.state.bidIncrementData
      );
    }
  };
  generateDropDownvalues = async (bidDetails: any, bidIncrementData: any) => {
    const iniPrice = bidDetails?.amount
      ? bidDetails?.amount
      : this.state?.lotData?.initialBiddingPrice;
    if (iniPrice && bidIncrementData) {
      const amou1 = iniPrice;
      let bidAmout = parseInt(amou1);
      let tempFullStr = "";
      (bidIncrementData || []).map((item: any, index: any) => {
        if (item.priceFrom <= bidAmout && item.priceTo >= bidAmout) {
          let increMA = item.increment;
          bidAmout = bidAmout + increMA;
          tempFullStr = tempFullStr + "___" + bidAmout;
          const priceDiff = item.priceTo - item.priceFrom;
          const repeat = (priceDiff / increMA).toFixed();
          const repeatT = parseInt(repeat) + 2;
          for (var i = 0; i < repeatT; i++) {
            if (item.priceFrom <= bidAmout && item.priceTo >= bidAmout) {
              bidAmout = bidAmout + increMA;
              tempFullStr = tempFullStr + "___" + bidAmout;
            }
          }
        }
      });
      const newListTemp = tempFullStr.split("___");
      if (newListTemp?.length < 10) {
        for (let index = 0; index < 10; index++) {
          bidAmout = bidAmout + 5000;
          // console.log("bidAmout", bidAmout);
          tempFullStr = tempFullStr + "___" + bidAmout;
        }
      }
      const { highestBidDetails } = this.state;
      highestBidDetails.dropdownvalues = tempFullStr.split("___");
      const newMaxBid = tempFullStr.split("___")[1];
      this.setState({ maximumBid: newMaxBid, maximumBid1: newMaxBid });
      this.setState({ highestBidDetails });
    }
  };
  addAmount = () => {};
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
    const timeZoneResp = await getTimeZones();
    this.setState({ timeZones: timeZoneResp.data });
  };
  getCollectionItems = async (id: any) => {
    const body = {
      page: 1,
      pageSize: 50,
      collectionId: id,
    };
    let resp = await getCollectionItemsCall(body);
    this.setState({ collectionItems: resp?.data });
  };
  getLotData = async (loadType: any) => {
    const id = this.props?.match?.params?.id;
    // console.log("const id", id);
    if (loadType == "onLoad") {
      this.setState({ isLoading: true });
    }
    const collectionItem = await getcollectionitembyidCall(id, LOGINID);
    this.setState({ isLoading: false });
    this.getCollectionData(collectionItem?.data?.collectionId, loadType);
    this.setState({ lotData: collectionItem?.data });
    this.getCollectionItems(collectionItem?.data?.collectionId);
    this.getHighestBidDetails(id);
    document.title = this.state.lotData?.title;
  };
  getCollectionData = async (id: any, loadType: any) => {
    if (loadType == "onLoad") {
      this.setState({ isLoading: true });
    }
    const collectionItem = await getCollectionByIdCall(id);
    this.setState({ isLoading: false });
    this.setState({ collectionData: collectionItem?.data }, () => {
      this.showPlaceBidBtnCheck();
    });
    this.updateShowLiveActionTime();
    this.updateLotStatus();
  };
  showPlaceBidBtnCheck = () => {
    const { collectionData } = this.state;
    // console.log("collectionData", collectionData);
    if (
      collectionData?.biddingStartDateOnUTC != undefined &&
      collectionData.auctionType == 2
    ) {
      // var currUTC = moment(collectionData?.biddingStartDateOnUTC).format("MM/DD/YYYY hh:mm:ss A UTC");
      // var currUTC1 = moment(collectionData?.biddingStartDateOnUTC).format("MM/DD/YYYY hh:mm:ss A UTC").getTime();
      // const collTime = new Date(currUTC).getTime();
      const gmtDateTime = moment.utc(
        collectionData?.biddingStartDateOnUTC,
        "YYYY-MM-DD h:mm:ss a"
      );
      const local_date = gmtDateTime.local().format("MM/DD/YYYY hh:mm:ss A");
      const collTime333 = new Date(local_date).getTime();
      // console.log("local_date",collTime333);

      const currTime = new Date().getTime();
      if (currTime > collTime333) {
        this.setState({
          showPlaceBidBtn: true,
          lotStatusText: "Lot Starts At",
        });
        // this.setState({});
        // document.getElementById("dateStatus").innerHTML=
        // document.getElementById("demo").innerHTML = "Lot Starts At";
      }
      const gmtDateTime1 = moment.utc(
        collectionData?.startOnUtc,
        "YYYY-MM-DD h:mm:ss a"
      );
      const local_date1 = gmtDateTime1.local().format("MM/DD/YYYY hh:mm:ss A");
      const collTime3331 = new Date(local_date1).getTime();

      // var currUTCStart = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
      // const collStartTime = new Date(currUTCStart).getTime();
      if (currTime > collTime3331) {
        this.setState({ showPlaceBidBtn: false });
      }
    } else if (collectionData.auctionType == 1) {
      if (collectionData?.startOnUtc != undefined) {
        // var fullDT1 = dd.substr(0, 11)+tt;
        // var currUTC = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
        // const collTime1 = new Date(currUTC).getTime();
        const gmtDateTime = moment.utc(
          collectionData?.startOnUtc,
          "YYYY-MM-DD h:mm:ss a"
        );
        const local_date = gmtDateTime.local().format("MM/DD/YYYY hh:mm:ss A");
        const collTime1 = new Date(local_date).getTime();

        const currTime1 = new Date().getTime();
        if (currTime1 < collTime1) {
          this.setState({ lotStatusText: "lot Starts At" });
        } else if (collectionData?.endOnUtc != undefined) {
          // var fullDT2 = ddd.substr(0, 11)+ttt;
          // var startCurrUTC = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
          //   var currUTC = moment(collectionData?.endOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
          //   const startCollTime2 = new Date(startCurrUTC).getTime();
          //   const collTime2 = new Date(currUTC).getTime();

          const gmtDateTime1 = moment.utc(
            collectionData?.startOnUtc,
            "YYYY-MM-DD h:mm:ss a"
          );
          const local_date1 = gmtDateTime1
            .local()
            .format("MM/DD/YYYY hh:mm:ss A");
          const startCollTime2 = new Date(local_date1).getTime();

          const gmtDateTime2 = moment.utc(
            collectionData?.endOnUtc,
            "YYYY-MM-DD h:mm:ss a"
          );
          const local_date2 = gmtDateTime2
            .local()
            .format("MM/DD/YYYY hh:mm:ss A");
          const collTime2 = new Date(local_date2).getTime();

          const currTime2 = new Date().getTime();
          if (startCollTime2 < currTime2 && currTime2 < collTime2) {
            // console.log("endOnUtcendOnUtc4444");
            this.setState({
              lotStatusText: "Lot Closes",
              showPlaceBidBtn: true,
            });
          } else if (currTime2 < collTime2) {
            // console.log("endOnUtcendOnUtc66666");
            this.setState({
              showPlaceBidBtn: true,
              lotStatusText: "Lot Closed At",
            });
          } else if (currTime2 > collTime2) {
            this.setState({ lotStatusText: "Lot Closed:" });
          }
        }
      }
    }
  };
  getmainImage = (item: any) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find((i: any) => i?.displayOrder == 1)
        ?.vurtualPath;
      return itemURRL;
    }
  };
  updateShowLiveActionTime = () => {
    const self = this;
    const { collectionData, lotData } = this.state;
    // console.log(collectionData);
    if (collectionData?.auctionType == 1) {
      // collectionData?.startOnUtc
      // collectionData?.endOnUtc
      // var dd = collectionData?.onlineStartDate;
      // var tt = collectionData?.onlineStartTime
      // var ddd = collectionData?.onlineEndDate;
      // var ttt = collectionData?.onlineEndTime
      if (collectionData?.startOnUtc != undefined) {
        // var fullDT1 = dd.substr(0, 11)+tt;
        var currUTC = moment(collectionData?.startOnUtc).format(
          "MM/DD/YYYY hh:mm:ss A UTC"
        );
        const collTime1 = new Date(currUTC).getTime();
        const currTime1 = new Date().getTime();
        if (currTime1 < collTime1) {
          var countDownDate = collTime1;
          self.StartTimerPage(countDownDate);
          var x = setInterval(function () {
            self.StartTimerPage(countDownDate);
          }, 1000);
          this.setState({ bidStartEndType: "starts" });
        } else if (collectionData?.endOnUtc != undefined) {
          // var fullDT2 = ddd.substr(0, 11)+ttt;
          var startUTC = moment(collectionData?.startOnUtc).format(
            "MM/DD/YYYY hh:mm:ss A UTC"
          );
          var currUTC = moment(collectionData?.endOnUtc).format(
            "MM/DD/YYYY hh:mm:ss A UTC"
          );
          const todayTime = moment.utc().format("MM/DD/YYYY hh:mm:ss A UTC");
          const expireTimeLast =
            this.state.lotData?.extentedExpiresOn ||
            this.state.lotData?.expiresOn;
          var expiresOnDate = moment
            .utc(expireTimeLast)
            .format("MM/DD/YYYY hh:mm:ss A UTC");
          const expiresOnTIme = new Date(expiresOnDate).getTime(); // lotData?.expiresOn
          if (todayTime > currUTC) {
            // this.setState({ isPastAuction: true });
          }
          const collTime2 = new Date(currUTC).getTime();
          const currTime2 = new Date().getTime();
          // if(currTime2 < collTime2){
          //     var countDownDate = collTime2;
          //     self.StartTimerPage(countDownDate)
          //     var x = setInterval(function() {
          //         self.StartTimerPage(countDownDate)
          //     }, 1000);
          //     this.setState({bidStartEndType: "ends"})
          // }
          // console.log("todayTime", todayTime);
          // console.log("todayTime", todayTime);
          // if (todayTime > startUTC && todayTime < currUTC) {
          var countDownDate = expiresOnTIme;
          self.StartTimerPage(countDownDate);
          var inrerValX = setInterval(function () {
            self.StartTimerPage(countDownDate);
          }, 1000);
          this.setState({ bidStartEndType: "ends" });
          this.removePreviousIntervals(inrerValX);
          // }
        }
      }
    }
  };
  removePreviousIntervals = (inrerValX: any) => {
    const { collectionData, lotData } = this.state;
    const newValue = localStorage.getItem("place_bid_newInterValues");
    const newInterArray = newValue?.split("__");
    newInterArray?.map((IIMM: any) => {
      if (IIMM) {
        const interLotId = IIMM.split("_")[0];
        const interValId = IIMM.split("_")[1];
        if (interLotId == lotData?.id) {
          window.clearInterval(parseInt(interValId));
        }
      }
    });
    var newInterV = newValue ? newValue : "";
    newInterV = newInterV ? newInterV + "__" : "";
    newInterV += `${lotData?.id}_${inrerValX}`;
    localStorage.setItem("place_bid_newInterValues", newInterV);
  };
  updateLotStatus = () => {
    const { lotData } = this.state;
    var currUTC = moment(
      lotData?.extentedExpiresOn || lotData?.expiresOn
    ).format("MM/DD/YYYY hh:mm:ss A UTC");
    const collTime = new Date(currUTC).getTime();
    const currTime = new Date().getTime();

    if (currTime > collTime) {
      this.setState({ lotFinalStatus: "closed", isPastAuction: true });
    }
  };
  StartTimerPage = (countDownDate: any) => {
    // console.log("countDownDate", countDownDate);
    var now = new Date().getTime();
    if (now < countDownDate) {
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      let daysTime = "";
      var hours1 = hours > 9 ? hours : `0${hours}`;
      var minutes1 = minutes > 9 ? minutes : `0${minutes}`;
      var seconds1 = seconds > 9 ? seconds : `0${seconds}`;
      if (days > 0) {
        daysTime = days + "d " + hours1 + " : " + minutes1 + " : " + seconds1;
      } else {
        daysTime = hours1 + " : " + minutes1 + " : " + seconds1;
      }
      this.setState({ showBiddingTie: daysTime });
    } else {
      this.getCollectionItemDataCall(this.state.lotData?.id);
      this.setState({
        lotFinalStatus: "closed",
        showAlertMessageAfterCloses: true,
      });

      // this.getCollectionData(this.state.collectionData?.id)
    }
  };
  PlaceAutoBid = async () => {
    if (this.props?.user?.id) {
      this.props.afterLoginTriggerPageCall({});
      const { maximumBid1, maximumBid } = this.state;
      if (maximumBid != "") {
        const { collectionData, currenciesList } = this.state;
        let currencyName = currenciesList.find(
          (i: any) => collectionData?.currency == i?.id
        )?.currencyCode;

        const body = {
          autoBid: {
            itemId: this.state.lotData?.id,
            customerId: this.props?.user?.id,
            currency: currencyName,
            maxAmount: maximumBid1 || maximumBid,
            type: "Online",
          },
        };
        this.setState({ pageLoading: true });
        triggerMixpanelEvent("auto_bid_click", {
          lotId: this.state.lotData?.id,
        });
        const resp = await autobidCallBroadCast(body);
        this.setState({ pageLoading: false });

        if (resp?.success) {
          this.setState({ autoBidSuccessPlacedSuccess: true });
          this.setState({
            autoBidSuccessText: "Your Bid has been successfully placed.",
          });
          triggerMixpanelEvent("auto_bid_success", {
            lotId: this.state.lotData?.id,
          });
        } else if (resp?.errors?.length > 0) {
          this.setState({ autoBidSuccessText: resp?.errors[0] });
          this.setState({ autoBidSuccessPlaced: true });
          triggerMixpanelEvent("auto_bid_error", {
            lotId: this.state.lotData?.id,
            error_message: resp?.errors[0],
          });
        } else if (resp?.message?.length > 0) {
          this.setState({ autoBidSuccessText: resp?.message });
          this.setState({ autoBidSuccessPlaced: true });
          triggerMixpanelEvent("auto_bid_error", {
            lotId: this.state.lotData?.id,
            error_message: resp?.message,
          });
        }
      }
    } else {
      const jsonD = {
        pageName: "placeBid",
        lotId: 0,
      };
      this.props.afterLoginTriggerPageCall(jsonD);
      this.props.LopinPopUpPage(true);
    }
  };
  placeQuickBid = async () => {
    if (this.state?.maximumBid != "") {
      const { collectionData, currenciesList } = this.state;

      let currencyName = currenciesList.find(
        (i: any) => collectionData?.currency == i?.id
      )?.currencyCode;

      const body = {
        quickBid: {
          currency: currencyName,
          itemId: this.state.lotData?.id,
          customerId: LOGINID,
          maxAmount: this.state?.maximumBid,
          type: "Online",
        },
      };
      this.setState({ pageLoading: true });
      triggerMixpanelEvent("quick_bid_click", {
        lotId: this.state.lotData?.id,
      });

      const resp = await quickbidCallBroadCast(body);
      this.setState({ pageLoading: false });

      // console.log("resp", resp);
      if (resp?.success) {
        triggerMixpanelEvent("quick_bid_success", {
          lotId: this.state.lotData?.id,
        });
        this.setState({ autoBidSuccessPlacedSuccess: true });
        this.setState({
          autoBidSuccessText: "Your Bid has been successfully placed.",
        });
      } else if (resp?.errors?.length > 0) {
        triggerMixpanelEvent("quick_bid_error", {
          lotId: this.state.lotData?.id,
          error_message: resp?.errors[0],
        });
        this.setState({ autoBidSuccessText: resp?.errors[0] });
        this.setState({ autoBidSuccessPlaced: true });
      } else if (resp?.message?.length > 0) {
        triggerMixpanelEvent("quick_bid_error", {
          lotId: this.state.lotData?.id,
          error_message: resp?.message,
        });
        this.setState({ autoBidSuccessText: resp?.message });
        this.setState({ autoBidSuccessPlaced: true });
      }
    }
  };
  gobackPage = () => {
    const { history } = this.props;
    history.push(`/lotDetails/${this.state.lotData?.id}`);
  };
  gobackPage1 = () => {
    const { history } = this.props;
    history.push(`/collectiondetails/${this.state.collectionData?.id}`);
  };

  gotoActiveBids = () => {
    this.setState({
      autoBidSuccessPlaced: false,
      autoBidSuccessPlacedSuccess: false,
      showAlertMessageAfterCloses: false,
    });
    this.props.history.push(
      `/collectionDetails/${this.state.lotData?.collectionId}?section=myActiveBids`
    );
  };
  gobackPageBid = () => {
    this.setState({
      autoBidSuccessPlaced: false,
      autoBidSuccessPlacedSuccess: false,
      showAlertMessageAfterCloses: false,
    });
    const { history } = this.props;
    this.getLotData("1");
    // history.push();
    // window.location.reload();
  };
  // handleShowPrevBids = () => {
  //   this.setState({ showPrevBids: true });
  // };
  handleShowPrevBids = (count: any) => {
    if (count > 0) {
      this.setState({ showPrevBids: true });
    }
  };
  howBidWorkCall = () => {
    this.props.history.push(`/howBiddingWorks/${this.state.lotData?.id}`);
    window.scrollTo(0, 0);
  };
  render() {
    const {
      collectionData,
      currenciesList,
      lotData,
      collectionItems,
      imagesList,
      timeZones,
      autoBidSuccessPlaced,
      autoBidSuccessPlacedSuccess,
      autoBidSuccessText,
      highestBidDetails,
      showBiddingTie,
      bidStartEndType,
      isPastAuction,
      showPlaceBidBtn,
      isLoading,
      pageLoading,
      highestBidResp,
      selectedCurrency,
      lotFinalStatus,
      showAlertMessageAfterCloses,
    } = this.state;
    // console.log("showPlaceBidBtn", showPlaceBidBtn);
    let currencyName = currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;

    let bannerTimeZone = timeZones.find(
      (i: any) => collectionData?.timeZoneId === i?.id
    )?.abbrivation;
    if (!bannerTimeZone) {
      bannerTimeZone = timeZones.find(
        (i: any) => collectionData?.timeZoneId === i?.id
      )?.name;
    }
    const rateAmount = selectedCurrency?.conversion_rates
      ? selectedCurrency?.conversion_rates[selectedCurrency?.base_code]
      : 1;
    const BidTextLive =
      collectionData?.auctionType == 2
        ? collectionData?.isAdvanceBidding
          ? "Advanced"
          : "Absentee"
        : "";
    // console.log(BidTextLive);
    // console.log("collectionData", collectionData);
    const disSize = isMobile ? 15 : 25;
    const collecttext =
      collectionData?.title?.length > disSize
        ? `${collectionData?.title?.substring(0, disSize)}...`
        : collectionData?.title;
    return (
      <>
        {isLoading ? (
          <>
            <div className="spinner_Loading_icon loaderClassforLotDetails">
              {/* <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div> */}
              <ContentLoader viewBox="0 0 800 400" height={400} width={800}>
                <rect x="470" y="104" rx="5" ry="5" width="220" height="10" />
                <rect x="470" y="124" rx="5" ry="5" width="220" height="10" />
                <rect x="470" y="144" rx="5" ry="5" width="220" height="10" />
                <rect x="64" y="18" rx="0" ry="0" width="346" height="200" />
                <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
                <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
                <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
                <rect x="470" y="18" rx="0" ry="0" width="300" height="25" />
                <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
                <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
                <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
                <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
                <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
                <rect x="470" y="179" rx="0" ry="0" width="70" height="30" />
                <rect x="560" y="179" rx="0" ry="0" width="70" height="30" />
                <rect x="112" y="250" rx="5" ry="5" width="60" height="60" />
                <rect x="198" y="250" rx="5" ry="5" width="60" height="60" />
                <rect x="295" y="250" rx="5" ry="5" width="60" height="60" />
                <rect x="70" y="340" rx="0" ry="0" width="131" height="12" />
                <rect x="70" y="370" rx="0" ry="0" width="566" height="12" />
                <rect x="70" y="390" rx="0" ry="0" width="566" height="12" />
              </ContentLoader>
            </div>
          </>
        ) : (
          <>
            <Container className="bid_two_page_content">
              {pageLoading && <Loader />}
              <div className="currency-top-section">
                <div className="d-inline-flex align-items-center all_pages_back_btn all_pages_back_btn_top pt-4 pb-2">
                  <span onClick={this.gobackPage1}>
                    {" "}
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M7 13L1 7L7 1"
                        stroke="#111111"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {collecttext}
                    {/* Collection Details */}
                  </span>

                  <span onClick={this.gobackPage}>
                    {" "}
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M7 13L1 7L7 1"
                        stroke="#111111"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Lot Details
                  </span>
                </div>
                <CurrencyConverterBox
                  handleShowCurrency={this.handleShowCurrency}
                />{" "}
              </div>
              <div className="bids_component_section">
                <Row>
                  <Col lg={6}>
                    <div className="bids_left_component">
                      <div className="bids_right_component d-lg-none d-block p-0">
                        {collectionData?.auctionType == 2 ? (
                          <>
                            <div className="reverse_time_text px-3 py-4 text-uppercase">
                              {`enter your ${BidTextLive} bid`}
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Online Bid {bidStartEndType} in: {showBiddingTie} */}
                          </>
                        )}
                      </div>
                      <div className="d-lg-block d-none">
                        <div className="bids_lots_text bg-white d-inline-block mt-3 mb-4">
                          Lot {lotData?.sno}
                        </div>
                        <h3 className="bids_lots_heading bids_lots_heading-top pt-3 pb-4 mb-0">
                          {lotData?.title}
                        </h3>
                        <div className="bids_lots_close_estimate_text_content d-flex pb-2 mb-4">
                          {collectionData?.auctionType == 1 && (
                            <>
                              <div className="bids_lots_close_estimate_text bids_lots_close_text w-50 pr-3">
                                <span className="bids_lots_close_estimate_label mb-1 d-block">
                                  Lot Closes (unless there is a extended
                                  bidding):
                                </span>
                                <p className="bids_lots_close_estimate_peregraph mb-0">
                                  {collectionData?.onlineEndDate && (
                                    <>
                                      {/* {moment(collectionData?.onlineEndDate).format("MMM Do, ")} {moment(collectionData?.onlineEndTime, 'HHmmss').format("h:mm a ")}  ({bannerTimeZone}) */}
                                      {moment(lotData?.expiresOn).format(
                                        "MMMM D, YYYY h:mm A"
                                      )}{" "}
                                      (
                                      {bannerTimeZone === "EST"
                                        ? "ET"
                                        : bannerTimeZone}
                                      )
                                    </>
                                  )}
                                </p>
                              </div>{" "}
                            </>
                          )}
                          <div className="bids_lots_close_estimate_text bids_lots_estimate_text w-50 pl-3">
                            <span className="bids_lots_close_estimate_label mb-1 d-block">
                              Estimate:
                            </span>
                            <p className="bids_lots_close_estimate_peregraph mb-0">
                              {currencyName}{" "}
                              {lotData?.estimatedPrice?.toLocaleString()}
                              {lotData?.estimatedPriceTo && (
                                <>
                                  - {currencyName}{" "}
                                  {lotData?.estimatedPriceTo?.toLocaleString()}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <Carousel fade controls={false}>
                          <Carousel.Item className="d-lg-flex">
                            <div className="curouselImageDiv">
                              <img
                                className="w-100"
                                src={this.getmainImage(lotData)}
                                alt="benner-slider-img"
                              />
                            </div>
                          </Carousel.Item>
                          {/* <Carousel.Item className="d-lg-flex">
                        <div className="">
                          <img
                            className="w-100"
                            src={WordBanner}
                            alt="benner-slider-img"
                          />
                        </div>
                      </Carousel.Item> */}
                        </Carousel>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bids_right_main_component position-relative">
                      <div className="bids_right_component">
                        {lotData?.bidsCount > 0 && lotData?.myBidsCount > 0 && (
                          <>
                            {!lotData?.outFromBid ? (
                              <div className="card-box-top-right-new green-colo-new">
                                You Have The Highest Bid
                              </div>
                            ) : (
                              <div className="card-box-top-right-new">
                                You Have Been Outbid
                              </div>
                            )}
                          </>
                        )}

                        <div className="d-lg-block timer-view-place-bid">
                          {isPastAuction &&
                          collectionData?.auctionType == 1 &&
                          false ? (
                            ""
                          ) : (
                            <>
                              <div className="reverse_time_text px-3 py-4 text-uppercase d-inline-block">
                                {collectionData?.auctionType == 2 ? (
                                  <>{`enter your ${BidTextLive} bid`}</>
                                ) : (
                                  <>
                                    {isPastAuction ? (
                                      <>Online Bidding Ended</>
                                    ) : (
                                      <>
                                        {bidStartEndType == "ends" &&
                                        showBiddingTie == "00 : 00 : 00" ? (
                                          <>Online Bidding Ended</>
                                        ) : (
                                          <>
                                            {bidStartEndType === "ends" ? (
                                              <>Lot Closes</>
                                            ) : (
                                              <>
                                                Online Bidding {bidStartEndType}{" "}
                                                in
                                              </>
                                            )}
                                            : {showBiddingTie}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="bids_enter_content bids_left_component">
                          <h3 className="bids_lots_heading pt-3 pb-4 mb-0 d-lg-none d-block">
                            {lotData?.title}
                          </h3>
                          {/* <div className="bids_lots_close_estimate_text_content d-flex pb-3 mb-3 d-lg-none d-block">
                        <div className="bids_lots_close_estimate_text bids_lots_estimate_text">
                          <span className="bids_lots_close_estimate_label mb-1 d-block">
                            Estimate
                          </span>
                          <p className="bids_lots_close_estimate_peregraph mb-0">
                            USD 8,000 - USD 12,000
                          </p>
                        </div>
                      </div> */}

                          <span className="bids_enter_current_bid_text d-block mb-1">
                            Current Bid: (
                            <span
                              onClick={() => {
                                this.handleShowPrevBids(lotData?.bidsCount);
                              }}
                              className="bids-count-no"
                            >
                              {lotData?.bidsCount} Bids
                            </span>
                            , Reserve{" "}
                            {highestBidDetails?.metResevedPrice ? "" : " Not"}{" "}
                            Met)
                          </span>
                          <h3 className="bids_enter_current_bid_amount mb-3">
                            {currencyName}{" "}
                            {highestBidDetails?.amount?.toLocaleString() ||
                              lotData?.initialBiddingPrice?.toLocaleString()}
                          </h3>
                          {selectedCurrency?.base_code && (
                            <div className="selected-currency-sec-name">
                              {(
                                (highestBidDetails?.amount ||
                                  lotData?.initialBiddingPrice) * rateAmount
                              )?.toLocaleString()}
                              {` `}
                              {selectedCurrency?.base_code}
                            </div>
                          )}
                          <div className="d-lg-block d-none">
                            <h2 className="bids_enter_heading">Enter Bid</h2>
                            <p className="bids_enter_peregraph">
                              Bids will be entered on your behalf up to your
                              selected maximum bid.
                            </p>
                          </div>
                          {lotData?.maxAmount > 0 && (
                            <div className="your-max-bid-amount-text">
                              Your current Maximum Bid Amount is {currencyName}{" "}
                              {lotData?.maxAmount?.toLocaleString()}
                            </div>
                          )}
                          {lotData?.botsCount > 1 && (
                            <div className="your-max-bid-amount-text">
                              One or more members are same highest bid
                              {/* {lotData?.maxAmount?.toLocaleString()} */}
                            </div>
                          )}
                          <Form>
                            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                              <Form.Label className="bids_enter_current_bid_text bids_enter_select_label">
                                Set Your Maximum Bid:
                              </Form.Label>
                              <Form.Control
                                as="select"
                                size="sm"
                                custom
                                className="custom_bid_select_box"
                                disabled={!lotData?.showPlaceBid}
                                onChange={(e) => {
                                  this.setState({
                                    maximumBid1: e.target.value,
                                  });
                                }}
                              >
                                {(highestBidDetails?.dropdownvalues || []).map(
                                  (val: any, index: any) => {
                                    const valeee = selectedCurrency?.base_code
                                      ? `(${(
                                          val * rateAmount
                                        )?.toLocaleString()} ${
                                          selectedCurrency?.base_code
                                        })`
                                      : "";
                                    return (
                                      <>
                                        {val != undefined && val != "" && (
                                          <option value={val} key={index}>
                                            {parseInt(val)?.toLocaleString()}{" "}
                                            {currencyName} {valeee}
                                          </option>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Form>
                          {/* <div
                            onClick={() => this.ShowCurrencyModal()}
                            className="bids_enter_currency_text align-items-center d-lg-flex d-none"
                          >
                            <img src={Currency} className="mr-1" />
                            Currency Converter
                          </div> */}
                          {lotData?.autoBidPlaced ? (
                            <>
                              <div className="bids_enter_btn_lots">
                                <Button
                                  disabled={!lotData?.showPlaceBid}
                                  onClick={this.PlaceAutoBid}
                                  className="bids_enter_lots_btn_same"
                                >
                                  <img src={BidBlack} /> CONFIRM TO SET MAXIMUM
                                  BID
                                </Button>
                                {lotData?.showQuickBid ? (
                                  <>
                                    <span className="d-block text-center text-center-or-text">
                                      Or
                                    </span>
                                    <Button
                                      disabled={!lotData?.showPlaceBid}
                                      onClick={this.placeQuickBid}
                                      className="bids_enter_lots_btn_same"
                                    >
                                      <img src={BidBlack} /> CONFIRM TO PLACE
                                      QUICK BID -{" "}
                                      {parseInt(
                                        this.state?.maximumBid
                                      )?.toLocaleString()}{" "}
                                      {currencyName}
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <div className="disable-quick-bid-text">
                                      {`Quick bid is activated when another bidder surpasses your maximum bid.`}
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="bids_enter_btn_lots">
                                <Button
                                  onClick={this.PlaceAutoBid}
                                  disabled={!lotData?.showPlaceBid}
                                  className="bids_enter_lots_btn_same"
                                >
                                  <img src={BidBlack} /> CONFIRM TO SET MAXIMUM
                                  BID
                                </Button>
                              </div>
                              <div className="disable-quick-bid-text">
                                {`Quick bid is activated when another bidder surpasses your maximum bid.`}
                              </div>
                            </>
                          )}
                          {/* {!lotData?.showQuickBid && (
                            
                          )} */}
                          {lotData?.myBidsCount > 0 && (
                            <div className="my-active-bids-count-btn">
                              {/* <button>GO TO MY ACTIVE BIDS</button> */}
                              <a
                                href={`/collectionDetails/${this.state.lotData?.collectionId}?section=myActiveBids`}
                              >
                                GO TO MY ACTIVE BIDS
                              </a>
                            </div>
                          )}
                          <div className="enter_bid_works">
                            {/* <div className="bids_enter_currency_text text-right mb-3 mt-0 d-lg-none">
                              <Link to="/" className="text-decoration-none">
                                <img src={Currency} className="mr-1" />
                                Currency Converter
                              </Link>
                            </div> */}
                            <div className="bidding-table-data-sec">
                              <p
                                className="bidWorksText"
                                onClick={() => this.howBidWorkCall()}
                              >
                                <img src={InfoIcon} className="mr-1" /> How
                                Bidding Works
                              </p>
                              <span></span>
                              <p
                                className="bidWorksText"
                                onClick={() => this.ShowBidIncrementModal()}
                              >
                                <img src={InfoIcon} className="mr-1" /> Bid
                                Increments Table
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </>
        )}
        <CustomModal
          show={autoBidSuccessPlaced}
          titleText={autoBidSuccessText}
          deleteText="OK"
          cancelText=""
          onCancel={() => {}}
          onDelete={() => {
            this.gobackPageBid();
          }}
        />
        <CustomModal
          show={showAlertMessageAfterCloses}
          titleText="The auction for this Lot has ended and we are currently awaiting the results. Please check back my active bids page."
          deleteText="OK"
          cancelText=""
          onCancel={() => {}}
          onDelete={() => {
            this.gotoActiveBids();
          }}
        />
        <CustomModal
          show={autoBidSuccessPlacedSuccess} //
          titleText={`Your Bid has been successfully placed. You can access all your Active Bids from My Active Bids Tab.`}
          deleteText="STAY HERE"
          cancelText="GO TO MY ACTIVE BIDS"
          bidSuccess={true}
          onCancel={() => {
            this.gotoActiveBids();
          }}
          onDelete={() => {
            this.gobackPageBid();
          }}
        />

        <BidIncrementTable
          ShowBidIncrementTable={this.state.ShowBidIncrementTable}
          hideModal={this.hideModal}
          bidIncrementTableData={this.state.bidIncrementData}
          currencyName={currencyName}
        />
        <CurrencyConverter
          ShowPopCurrency={this.state.ShowPopCurrency}
          hideModal={this.hideModal}
        />
        <PrevBidDetails
          ShowPrevBids={this.state.showPrevBids}
          hideModal={this.hideModal}
          lotId={this.state.lotData?.id}
          currencyName={currencyName}
          collectionData={this.state.collectionData}
        />
      </>
    );
  }
}

// export default PlaceBid;

// export default lotDetails;
const mapStateToProps = (state: any) => {
  return state;
};
// export default withRouter(LoginPopup);
export default connect(mapStateToProps, actionCreators)(PlaceBid);
