import { Link } from "react-router-dom";

import SliderOne from "./../../assets/image/slide1.jpg";
import SliderTwo from "./../../assets/image/slide2.jpg";
import moment from "moment";

const ItemCard = (props: any) => {
  const { item } = props;
  return (
    <>
      <Link
        to={`/collectionDetails/${item.id}`}
        key={item.id}
        className="action_slider_content Collection_action_slider_content text-decoration-none text-dark"
      >
        <div className="action_slider_img overflow-hidden">
          <img src={item.coverImage} className="img-fluid border-0" />
        </div>
        <div className="action_slider_body_content">
          <ul className="d-flex align-items-center pl-0 mb-0 flex-wrap">
            <li className="action_slider_date_text pr-2 border-0">
              {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
            </li>
            <li className="px-2">
              {moment(item?.auctionStartTime, "HHmmss").format("h:mm A")}{" "}
              {item?.timeZoneAbbr === "EST" ? "ET" : item?.timeZoneAbbr}
            </li>
            <li className="px-2">
              <Link
                to={item.actionLink}
                className="text-decoration-none text-dark"
              >
                {item.auctionType}
              </Link>
            </li>
          </ul>
          <h3 className="action_slider_heading home-page-title-coll overflow-hidden lineClamp">
            {item.title}
          </h3>
          <Link
            to={`/${
              item.auctionType === "Live Auction2"
                ? `live-auction-landing/${item.id}`
                : `collectionDetails/${item.id}`
            }`}
            className="action_slider_browse_btn d-table ml-auto text-decoration-none"
          >
            BROWSE AUCTION
          </Link>
        </div>
      </Link>
    </>
  );
};
export default ItemCard;
