import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import AccountSlideBar from "../../components/AccountSlideBar";
import Accordion from "react-bootstrap/Accordion";
import "./style.css";
import UpcomingActionSlider from "../../components/UpcomingActionSlider";
import ActiveActionsSlider from "../../components/ActiveActionsSlider";

import { getAllAuctions } from "./../../library/Requests";

class ActionPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      AllCollections: {},
      upCommingAuctions: [],
    };
  }
  componentDidMount() {
    this.getAllSiteManagers();
  }
  getAllSiteManagers = async () => {
    const body = {
      page: 1,
      pageSize: 10,
      getOnlyTotalCount: false,
      sortColumn: "StartOnUtc",
      sortDirection: "desc",
    };
    const resp = await getAllAuctions(body);
    this.setState({
      AllCollections: resp,
      upCommingAuctions: resp?.upCommingAuctions,
    });
  };
  selectActionType = (e: any) => {
    const { AllCollections, upCommingAuctions } = this.state;
    const upCommingFull = AllCollections?.upCommingAuctions;
    const Atype = e.target.value;

    if (Atype == "all") {
      this.setState({ upCommingAuctions: upCommingFull });
    } else if (Atype == "online") {
      let finaalAction = upCommingFull.filter(
        (item: any) => item.auctionType == "Online Auction"
      );
      this.setState({ upCommingAuctions: finaalAction });
    } else if (Atype == "live") {
      let finaalAction = upCommingFull.filter(
        (item: any) => item.auctionType == "Live Auction"
      );
      this.setState({ upCommingAuctions: finaalAction });
    }
  };
  render() {
    const { AllCollections, upCommingAuctions } = this.state;
    const activeCount = this.state.AllCollections?.onGoingAuctions?.length ?? 0;
    const upCommingAuctionsCount =
      AllCollections?.upCommingAuctions?.length ?? 0;
    return (
      <>
        {activeCount > 0 && (
          <>
            <div className="custom_slider_heading_filter d-flex align-items-center">
              <h3 className="mb-0 mr-auto position-relative pl-3">
                Active Auctions
              </h3>
            </div>
            <ActiveActionsSlider AllCollections={AllCollections} />
          </>
        )}
        {/* <div className="custom_slider_heading_filter d-flex align-items-center">
          <h3 className="mb-0 mr-auto position-relative pl-3">
            Active Auctions
          </h3>
        </div> */}
        {activeCount === 0 && (
          <div className="tank-you-page-sec">
            <p>THANK YOU FOR MAKING OUR FIRST AUCTION A SUCCESS!</p>

            <p>
              WE’LL BE BACK SOON WITH DATES AND TIMES FOR OUR NEXT AUCTIONS.
            </p>
          </div>
        )}

        {upCommingAuctionsCount > 0 && (
          <>
            <div className="custom_slider_heading_filter d-flex align-items-center">
              <h3 className="mb-0 mr-auto position-relative pl-3">
                Upcoming Auctions
              </h3>
              <div className="custom_slider_heading_filter_content d-flex align-items-center justify-content-end">
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="d-flex align-items-center mb-0 w-100"
                >
                  <Form.Label className="w-100 text-right mr-3 mb-0">
                    Filter By
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.selectActionType}
                    className="shadow-none"
                  >
                    <option value="all">All</option>
                    <option value="online">Online</option>
                    <option value="live">Live</option>
                  </Form.Control>
                </Form.Group>
                <Link
                  to="/auction-calendar"
                  className="view_all_btn text-decoration-none"
                >
                  View All
                </Link>
              </div>
            </div>
            {upCommingAuctions?.length == 0 ? (
              <div>No Results Found</div>
            ) : (
              <UpcomingActionSlider upCommingAuctions={upCommingAuctions} />
            )}
          </>
        )}
      </>
    );
  }
}

export default ActionPage;
