import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import time_fill from "./../../assets/main/time-fill.png";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import MyBidCardRow from "./MyBidCardRow";
import { MyActiveBidsStyled } from "./MyActiveBids.styled";
import { Col, Row, Button } from "react-bootstrap";
import { getAllActiveBids } from "../../library/Requests";
import {
  autobidCallBroadCast,
  quickbidCallBroadCast,
} from "./../../library/Requests/PlaceBid";
import { getbidincrementCall } from "./../../library/Requests";
import CustomModal from "./../../components/CustomModel";
import Loader from "./../../components/Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { triggerMixpanelEvent } from "./../../Scenes/common";

const MyActiveBids = (props: any) => {
  const {
    biddingShowNo,
    collectionData,
    userDetails,
    bannerTimeZone,
    currencyName,
    isLoadingParent,
    getCollectionDataCall,
  } = props;
  const [allMyActiveBids, setAllMyActiveBids] = useState([]);
  const [autoBidSuccessText, setAutoBidSuccessText] = useState("");
  const [autoBidSuccessPlaced, setAutoBidSuccessPlaced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bidIncrementData, setBidIncrementData] = useState([] as any);

  // const userD = useSelector((RootState: any) => RootState);

  // console.log("userD", userD);
  useEffect(() => {
    // console.log("collectionData?.id MyActiveBids ", collectionData?.id);
    if (collectionData?.id) {
      getAllBids();

      hetDropDownValsData();
    }
  }, [collectionData?.id]);
  // const getLatestDataFromSignal = () => {
  //   // for (var i = 0 as any; i < 1000; i++) {
  //   //   window.clearInterval(i);
  //   // }

  //   // getAllBids();
  //   getCollectionDataCall();
  // };
  const getAllBids = async () => {
    const newJson = {
      CollectionId: collectionData?.id,
      CustomerId: userDetails?.id,
      Page: 1,
      PageSize: 100,
    };
    setIsLoading(true);
    const respN = await getAllActiveBids(newJson);
    setIsLoading(false);
    if (respN?.data?.length > 0) {
      setAllMyActiveBids(respN?.data);
    }
  };
  const hetDropDownValsData = async () => {
    const jsonVal = await getbidincrementCall();
    setBidIncrementData(jsonVal?.increment);
  };
  const placeQuickBidCall = async (body: any) => {
    setIsLoading(true);
    triggerMixpanelEvent("quick_bid_click", {
      lotId: body?.itemId,
      myActiveBids: true,
    });
    const resp = await quickbidCallBroadCast(body);
    setIsLoading(false);
    if (resp?.success) {
      setAutoBidSuccessPlaced(true);
      setAutoBidSuccessText("Your Bid has been successfully placed.");
      triggerMixpanelEvent("quick_bid_success", {
        lotId: body?.itemId,
        myActiveBids: true,
      });
      // getAllBids();
    } else if (resp?.errors.length > 0) {
      setAutoBidSuccessText(resp?.errors[0]);
      setAutoBidSuccessPlaced(true);
      triggerMixpanelEvent("quick_bid_error", {
        lotId: body?.itemId,
        error_message: resp?.errors[0],
        myActiveBids: true,
      });
    }
  };
  const autobidCallBroadCastCall = async (body: any) => {
    setIsLoading(true);
    triggerMixpanelEvent("auto_bid_click", {
      lotId: body?.itemId,
      myActiveBids: true,
    });
    const resp = await autobidCallBroadCast(body);
    setIsLoading(false);
    if (resp?.success) {
      setAutoBidSuccessPlaced(true);
      setAutoBidSuccessText("Your Bid has been successfully placed.");
      triggerMixpanelEvent("auto_bid_success", {
        lotId: body?.itemId,
        myActiveBids: true,
      });
    } else if (resp?.errors?.length > 0) {
      setAutoBidSuccessText(resp?.errors[0]);
      setAutoBidSuccessPlaced(true);
      triggerMixpanelEvent("auto_bid_error", {
        lotId: body?.itemId,
        error_message: resp?.errors[0],
        myActiveBids: true,
      });
    } else if (resp?.message?.length > 0) {
      setAutoBidSuccessText(resp?.message);
      setAutoBidSuccessPlaced(true);
      triggerMixpanelEvent("auto_bid_error", {
        lotId: body?.itemId,
        error_message: resp?.message,
        myActiveBids: true,
      });
    }
  };
  // console.log("all bids sec");
  return (
    <>
      <MyActiveBidsStyled>
        <div className="my_active_bids_sec">
          {(isLoading || props?.isLoadingParent) && <Loader />}

          <CustomModal
            show={autoBidSuccessPlaced}
            titleText={autoBidSuccessText}
            deleteText="OK"
            cancelText=""
            onCancel={() => {}}
            onDelete={() => {
              setAutoBidSuccessPlaced(false);
            }}
          />

          {/* <div className="coll-close-time">
            <div>
              <div className="local-text-red-coll">
                <img src={time_fill} />
                {biddingShowNo}
                <span> Until lots Close</span>
              </div>
            </div>
          </div> */}
          {/* <div className="my-active-bids-list filter_list_data_column11">
            {allMyActiveBids?.length > 0 ? (
              <>
                <div className="my-active-bids-list-con">
                  <Row>
                    {allMyActiveBids?.map((myLot: any) => {
                      return (
                        <Col className="data_column_box" md={6} sm={6}>
                          <MyBidCard
                            myLot={myLot}
                            bannerTimeZone={bannerTimeZone}
                            currencyName={currencyName}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </>
            ) : (
              <>
                <div className="my-no-active-bids">No Active Bids</div>
              </>
            )}
          </div> */}
          <div className="my-active-bids-list filter_list_data_column_row">
            {allMyActiveBids?.length > 0 ? (
              <div className="my-active-bids-list-con-row">
                {allMyActiveBids?.map((myLot: any, index: any) => {
                  return (
                    <>
                      {/* && index === 0 */}
                      {myLot?.outFromBid && (
                        <MyBidCardRow
                          myLot={myLot}
                          bannerTimeZone={bannerTimeZone}
                          currencyName={currencyName}
                          userDetails={userDetails}
                          placeQuickBidCall={placeQuickBidCall}
                          autobidCallBroadCastCall={autobidCallBroadCastCall}
                          bidIncrementData={bidIncrementData}
                          youHaveHighestBid={false}
                          collectionData={collectionData}
                        />
                      )}
                    </>
                  );
                })}
                {allMyActiveBids?.map((myLot: any) => {
                  return (
                    <>
                      {!myLot?.outFromBid && (
                        <MyBidCardRow
                          myLot={myLot}
                          bannerTimeZone={bannerTimeZone}
                          currencyName={currencyName}
                          userDetails={userDetails}
                          placeQuickBidCall={placeQuickBidCall}
                          autobidCallBroadCastCall={autobidCallBroadCastCall}
                          bidIncrementData={bidIncrementData}
                          youHaveHighestBid={true}
                          collectionData={collectionData}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            ) : (
              <div className="my-no-active-bids">No Active Bids</div>
            )}
          </div>
        </div>
      </MyActiveBidsStyled>{" "}
    </>
  );
};
export default MyActiveBids;
