import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";

import filterListImg from "../../assets/image/slide1.jpg";
import bidBlack from "../../assets/image/bid-black.png";
import bidWhite from "../../assets/image/bid-white.png";
import noImage from "../../assets/image/noImage.jpg";
import PrevBidDetails from "../../components/PrevBidDetails/PrevBidDetails";

import "./css/BrowseTabGridContent.css";
import { isMobile } from "react-device-detect";

interface Props {
  currenciesList?: any;
  collectionData?: any;
  collectionItems?: any;
  followBid?: any;
  unFollowBid?: any;
  isPastAuction?: any;
  onHovering?: any;
  isLoading?: any;
}

export const BrowseTabGridContent: React.FC<Props> = (Props) => {
  const [hovering, setHovering] = useState(false);
  const [showPrevBids, setShowPrevBids] = useState(false);
  const [selectedLotId, setSelectedLotId] = useState("");

  const { collectionData } = Props;
  let currencyName = Props?.currenciesList.find(
    (i: any) => collectionData?.currency == i?.id
  )?.currencyCode;

  const getmainImage = (item: any) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i: any) => i.displayOrder == 1 && !i.deleted
      )?.vurtualPath;
      if (
        itemURRL === "string" ||
        itemURRL === null ||
        itemURRL === undefined ||
        itemURRL === ""
      ) {
        return noImage;
      } else {
        return itemURRL;
      }
    }
  };
  const handleShowPrevBids = (count: any, lId: any) => {
    if (count > 0) {
      setSelectedLotId(lId);
      setShowPrevBids(true);
    }
  };
  const hideModal = () => {
    setShowPrevBids(false);
  };

  return (
    <>
      <div className="browse_listing_right_tab_inner_content">
        <PrevBidDetails
          ShowPrevBids={showPrevBids}
          hideModal={hideModal}
          lotId={selectedLotId}
          currencyName={currencyName}
          collectionData={collectionData}
        />
        <div className="browse_listing_inner_content">
          {Props?.collectionItems?.length > 0 ? (
            <Row className="filter_list_data_column">
              {(Props?.collectionItems || []).map((item: any, index: any) => {
                const currentBid1 =
                  item?.currentBid || item?.initialBiddingPrice;

                return (
                  <Col className="data_column_box" md={4} sm={6}>
                    <div className="lot_number d-inline-block">
                      Lot {item.sno}
                    </div>
                    <div className="lots_slider_img">
                      <Link to={`/lotDetails/${item.id}`}>
                        <img
                          src={getmainImage(item)}
                          alt="filterListImg"
                          className="w-100"
                        />
                      </Link>
                    </div>
                    <div className="lots_slider_cont_details">
                      <Link to={`/lotDetails/${item.id}`}>
                        <h4 className="lineClampLot">{item?.title}</h4>
                      </Link>
                      {item?.bidsCount > 0 &&
                        item?.status !== 6 &&
                        item?.status !== 7 &&
                        item?.myBidsCount > 0 && (
                          <>
                            {!item?.outFromBid ? (
                              <div className="card-box-top-right-new card-box-top-right-new-cd green-colo-new">
                                You Have The Highest Bid
                              </div>
                            ) : (
                              <div className="card-box-top-right-new card-box-top-right-new-cd">
                                You Have Been Outbid
                              </div>
                            )}
                          </>
                        )}

                      <div className="lots_cost lots_cost_lat lots_cost_lat_coll_details">
                        {item?.status === 6 ? (
                          <>
                            <span className="d-block lotDetailsTextRightSide">
                              Lot Sold:
                            </span>
                            <span className="strong d-block">
                              {currencyName} {item?.soldFor?.toLocaleString()}
                            </span>
                          </>
                        ) : item?.status === 7 ? (
                          <span className="d-block lotDetailsTextRightSide">
                            Bidding is Closed
                          </span>
                        ) : (
                          <>
                            <span className="d-block lotDetailsTextRightSide">
                              Current Bid: {` (`}
                              <span
                                className="bids-count-no"
                                onClick={() => {
                                  handleShowPrevBids(item?.bidsCount, item?.id);
                                }}
                              >
                                {item?.bidsCount} Bids
                              </span>
                              , Reserve
                              {item?.lotMetReservice ? "" : " Not"} Met)
                            </span>
                            <span className="strong d-block ">
                              {currencyName} {currentBid1?.toLocaleString()}
                              {/* {highestBidDetails?.amount?.toLocaleString()} */}
                            </span>
                          </>
                        )}
                      </div>

                      <div className="price_list_btn d-flexjustify-content-between lot-grid-view-sec-estimate-follow">
                        <div className="lots_cost price-block">
                          <span className="d-block">{`Estimate`}</span>
                          <span className="strong d-block">
                            <p>
                              {currencyName}{" "}
                              {item?.estimatedPrice?.toLocaleString()}
                              {item?.estimatedPriceTo && (
                                <>
                                  {" "}
                                  - {currencyName}{" "}
                                  {item?.estimatedPriceTo?.toLocaleString()}{" "}
                                </>
                              )}
                            </p>
                          </span>
                        </div>
                        {!Props?.isPastAuction && (
                          <div className="btn_lots btn_lots_font_issue ">
                            {item?.ifollowed ? (
                              <Button
                                onClick={() => {
                                  Props?.unFollowBid(item?.id);
                                }}
                                className="lots_btn_same onHover d-inline-flex followingThisBid text-center align-items-center text-decoration-none"
                              >
                                FOLLOWING
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  Props?.followBid(item?.id);
                                }}
                                className="lots_btn_same btnHover d-inline-flex text-center bg-white align-items-center text-decoration-none"
                              >
                                FOLLOW
                              </Button>
                            )}
                            {/* {isMobile ? (
                              ""
                            ) : ( */}
                            <Link
                              onMouseEnter={() =>
                                Props?.onHovering(item?.id, true)
                              }
                              onMouseLeave={() =>
                                Props?.onHovering(item?.id, false)
                              }
                              to={`/lotDetails/${item.id}`}
                              className="lots_btn_same btnHover d-inline-flex text-center bg-white align-items-center text-decoration-none"
                            >
                              <img
                                src={item?.hovering ? bidWhite : bidBlack}
                                className="bid_icon mr-1 "
                              />
                              BID
                            </Link>
                            {/* )} */}
                          </div>
                        )}
                      </div>
                      {/* {item?.status === 6 ? (
                        <p className="lotSoldPriceList">
                          Lot Sold:{" "}
                          <span>
                            {currencyName} {item?.soldFor?.toLocaleString()}
                          </span>
                        </p>
                      ) : item?.status === 7 ? (
                        <p className="lotunSoldPriceList"> Bidding is Closed</p>
                      ) : (
                        <></>
                      )} */}
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : !Props?.isLoading ? (
            <Row>
              <div className="no-results-sec-coll-details">
                Oops! It seems we couldn’t find any results for your search.
                please try with different keyword.
              </div>
            </Row>
          ) : null}
          {/* <div className="load_more_list_btn text-center">
            <Link to="/collectionDetails/1" className="text-white d-inline-block text-decoration-none">Load More</Link>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default BrowseTabGridContent;
