import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import TopBannerSlider from "../../components/TopBannerSlider";
import TheWordSlider from "../../components/TheWordSlider";
import LotsSlider from "../../components/LotsSlider";
import ActionPage from "../ActionsPage";
import ComingSoonPage1 from "./../ComingSoonPage1/ComingSoonPage1";
import ComingSoonPage2 from "./../ComingSoonPage2/ComingSoonPage2";
import { STATIC_GATE_CODE } from "./../../library/constants";
import { getGateCode } from "./../../Scenes/common";
class Home extends React.Component<any, any> {
  render() {
    console.log("getGateCode", getGateCode());
    console.log("STATIC_GATE_CODE", STATIC_GATE_CODE);
    return (
      <>
        {STATIC_GATE_CODE === getGateCode() ? (
          <>
            <TopBannerSlider />
            <div className="custom_action_slider_section">
              <Container>
                <ActionPage />
              </Container>
            </div>
            <div className="word_slider_section">
              <Container>
                <div className="custom_slider_heading_filter d-flex align-items-center">
                  <h3 className="mb-0 mr-auto position-relative pl-3">
                    the Word
                  </h3>
                </div>
              </Container>
              <div className="word_slider_bg_section">
                <Container>
                  <TheWordSlider />
                </Container>
              </div>
            </div>
            <div className="custom_lots_slider_section">
              {/* <Container> */}

              <LotsSlider />
              {/* </Container> */}
            </div>
          </>
        ) : (
          <>
            <ComingSoonPage2 />{" "}
          </>
        )}
      </>
    );
  }
}
export default Home;
