import React, { Component } from "react";
import "./css/style.css";
import { Row, Col, Accordion, Card, Button, Form } from "react-bootstrap";
import Article1Main from "./../../assets/articleimages/about-us-page.png";
import { ReactComponent as Line } from "./../../assets/Line.svg";

class AboutUs extends React.Component {
  componentDidMount() {
    document.title = "FAHH-about us";
  }
  render() {
    return (
      <div className="AboutUsContainer wrapper">
        <div className="topbannerSection">
          <div className="manageSiteManagerView manageSiteManagerView-aboutus">
            <Row className="imageSectionView">
              <img
                src={Article1Main}
                className="d-block w-100 tk "
                alt="clicked-slider-img"
              />
            </Row>
            <div className="BottomSectionView">
              <Row className="bannerContentRow">
                <Col className="bannerTextContent">
                  <p className="about-us-content-page">About us</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="about-us-content-inner-sec">
          <Row className="headerPreviewContent-aboutus">
            <p className="PreviewTitle">{`About FineArt.HipHop`} </p>
          </Row>

          <Row className="headersubContent-aboutus">
            FineArt.HipHop is the trusted and authentic auction platform and
            marketplace for Hip Hop inspired fine art and collectibles.
            FineArt.HipHop is founded on the premise that the Hip Hop lifestyle
            is undoubtedly the most diverse and pervasive lifestyle in the world
            today. In this 50th Year of Hip Hop it is fitting that a company
            founded by people from the Hip Hop community rises to acknowledge,
            curate, honor and expose to the world the unique and valuable art to
            which Hip Hop culture has given rise. Unlike other music genres
            before it, Hip Hop expanded to encompass the entirety of life for
            its generation – extending from music to fashion to politics to
            economics to the sciences and more. The art from the Hip Hop
            generation represents this breadth and FineArt.HipHop will
            authentically seek out and bring to market these valuable artifacts
            for the world to see and collectors and aficionados to cherish. We
            are more than excited as we begin this journey and welcome all to
            come along with us as the influence of Hip Hop continues to grow and
            inspire.
          </Row>
        </div>
      </div>
    );
  }
}
export default AboutUs;
