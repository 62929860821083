import React, { useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Logo_new from "./../../assets/main/fahh_logo.png";

import {
  Nav,
  Container,
  Form,
  FormControl,
  Button,
  Dropdown,
} from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import Logo from "./../../assets/image/Footer_Logo.svg";
// import Logo_new from "./../../assets/magento_logo_new.png";
import ModalLogo from "../../assets/image/Logo.svg";
// import { loginActionCall } from "./../../library/Requests"
import LoginPopup from "../../components/LoginPopup";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";
import "./css/styles.css";
import { isMobile } from "react-device-detect";
import { LOGINID } from "./../../Scenes/common";
import { getOutBidByCustomerCall } from "./../../library/Requests/PlaceBid";
import Marquee from "react-fast-marquee";
import * as signalR from "@microsoft/signalr";
import { SERVER_IP } from "./../../library/constants.js";
// import { useLocation } from "react-router-dom";
import WelcomeModel from "./../../components/WelcomeModel/WelcomeModel";
import { triggerUserFprofileInMixpanel } from "./../../Scenes/common";
class Header extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      userName: "",
      password: "",
      erroruserName: false,
      errorpassword: false,
      errUserNameText: "",
      errpasswordText: "",
      NOData: false,
      userId: "",
      loginError: "",
      showHeader: true,
      outBidsData: [],
      showWelcomeModel: false,
    };
  }
  componentDidMount() {
    console.log("this.props.location");
    const pageURl = this.props?.location?.pathname;
    this.checkLogin();
    this.updateUserDetailstoProps();
    if (pageURl.indexOf("/live-auction-landing/") > -1) {
      this.setState({ showHeader: false });
    }
    triggerUserFprofileInMixpanel();
    const _this = this;

    setTimeout(() => {
      _this.getAllActiveLots();
    }, 200);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .withAutomaticReconnect()
      .build();
    connection.on("BidInsertSignal", (response) => {});
    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          _this.getAllActiveLots();
        });
      })
      .catch((err) => console.error(err.toString()));
    const localText = localStorage.getItem("welcomeModelText");
    if (localText !== "done") {
      this.setState({ showWelcomeModel: true });
      localStorage.setItem("welcomeModelText", "done");
    }
  }
  getAllActiveLots = async () => {
    if (LOGINID) {
      const respN = await getOutBidByCustomerCall(LOGINID);
      console.log("respN", respN);
      if (respN?.data?.length > 0) {
        this.setState({ outBidsData: respN?.data });
        // var respNew = [];
        // respN?.data.map((itm: any) => {
        //   respNew.push(itm?.collectionTitle);
        // });
      }
    }
  };
  gotoSignupPage = () => {
    this.hideModal();
    this.props.history.push(`/sign-up`);
  };
  checkLoginPage = () => {
    const userD = localStorage.getItem("customerInfoDetails");
    if (userD != undefined && userD != null && userD != "") {
      this.props.history.push(`/profile`);
    } else {
      this.props.afterLoginTriggerPageCall({});
      this.props.LopinPopUpPage(true);
    }
  };
  checkLogin = () => {
    const userD = localStorage.getItem("customerInfoDetails");
    if (userD != undefined && userD != null && userD != "") {
      const userIn = JSON.parse(userD);
      if (userIn?.id) {
        this.setState({ userId: userIn?.id });
      }
    }
  };
  updateUserDetailstoProps = () => {
    const userD = localStorage.getItem("customerInfoDetails");
    if (userD != undefined && userD != null && userD != "") {
      const userIn = JSON.parse(userD);
      if (userIn?.id) {
        this.props.loginSuccessData(userIn);
      }
    }
  };
  handleLogout = () => {
    localStorage.removeItem("customerInfoDetails");
    localStorage.clear();
    window.location.href = "/";
  };
  hideModal = () => {
    this.setState({ showWelcomeModel: false });
  };
  render() {
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const {
      show,
      userName,
      password,
      erroruserName,
      errorpassword,
      errUserNameText,
      errpasswordText,
      userId,
      loginError,
      showHeader,
      outBidsData,
      showWelcomeModel,
    } = this.state;
    const locationUrl = this.props?.location?.pathname ?? "";
    const collectionId = locationUrl?.includes("collectionDetails")
      ? locationUrl?.split("/")[2]
      : "";
    // console.log("propsprops", locationUrl);
    // console.log("collectionId", collectionId);

    return (
      <>
        {showHeader && (
          <>
            <WelcomeModel
              showWelcomeModel={showWelcomeModel}
              hideModal={this.hideModal}
              gotoSignupPage={this.gotoSignupPage}
            />
            <div className="header-top-box">
              <div className="header-top-box-content">
                <ul>
                  <li>
                    <a href="/sellingGuides">How To Sell</a>
                  </li>
                  <li>
                    <a href="/contactus">Contact</a>
                  </li>
                  <li>
                    <a href="/howBiddingWorks">How Bidding Works?</a>
                  </li>
                </ul>
              </div>
            </div>
            {
              outBidsData?.length > 0 &&
                collectionId &&
                // <div className="new-item-sec-map-list">
                //   <div className="banner-notification-sec banner-notification-sec">
                /* <Marquee pauseOnHover={true} play={true} speed={30}> */
                outBidsData?.map((itmm: any, index: any) => {
                  const newItemT = itmm?.collectionTitle;
                  return (
                    <>
                      {collectionId == itmm?.collectionId && (
                        <div className="new-item-sec-map-list">
                          <div className="banner-notification-sec banner-notification-sec">
                            <span className="row-item-sec-pay">
                              <a
                                href={`/collectionDetails/${itmm?.collectionId}?section=myActiveBids`}
                              >
                                {" "}
                                {`You have been outbid on One or More Lots  at ${newItemT}. `}
                              </a>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })

              //   </div>
              // </div>
            }
            <div className="page_header bg-white">
              <Container>
                <Navbar expand="lg">
                  <Navbar.Brand href="/" className="p-0">
                    <img
                      className="logo_main_new"
                      src={Logo_new}
                      alt="header-logo"
                    />
                  </Navbar.Brand>
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="border-0 p-0"
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto alignCenter">
                      <Dropdown className="actionsDropdown">
                        <Dropdown.Toggle className="actionDropdownToggle">
                          {isMobile ? "Auctions" : "AUCTIONS"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="noBorder">
                          <Dropdown.Item
                            href="/auction-calendar"
                            className="dropdownText"
                          >
                            CALENDAR
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="/auction-results"
                            className="dropdownText"
                          >
                            RESULTS{" "}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Nav.Link href="/sellingGuides" className="linksSell">
                        Sell
                      </Nav.Link>
                      <Nav.Link href="/aboutus" className="linksAbout">
                        About
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                  <Form className="position-relative header-search-input">
                    {/* <Button className="position-absolute p-0 d-flex align-items-center justify-content-center bg-transparent border-0 shadow-none">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                          stroke="#555555"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.0004 21L16.6504 16.65"
                          stroke="#555555"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button> */}
                    {/* <FormControl
                      type="text"
                      placeholder="Search Here"
                      className="shadow-none"
                    /> */}
                  </Form>
                  <Button
                    id="header_login_btn"
                    className="header_user_btn bg-transparent border-0 shadow-none"
                    onClick={() => {
                      this.checkLoginPage();
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                  <LoginPopup />
                </Navbar>
              </Container>
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return state;
};
// export default withRouter(LoginPopup);
export default connect(mapStateToProps, actionCreators)(withRouter(Header));

// export default  withRouter(Header);
