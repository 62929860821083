export const Data = [
  {
    auctionStartDate: "2021-03-03T19:15:00Z",
    auctionEndTime: "2021-03-13T19:15:00Z",
    title: "Big Daddy Kane graphical Illustration",
    auctionType: "Online",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-03-18T09:05:00Z",
    auctionEndTime: "2021-03-26T09:05:00Z",
    title: "March Collection 2",
    auctionType: "Live",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-03-20T10:15:00Z",
    auctionEndTime: "2021-03-28T10:15:00Z",
    title: "March Collection 1",
    auctionType: "Online",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-03-03T13:45:00Z",
    auctionEndTime: "2021-03-06T13:45:00Z",
    title: "Big Daddy Kane graphical Illustration",
    auctionType: "Live",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-04-09T08:15:00Z",
    auctionEndTime: "2021-04-23T08:15:00Z",
    title: "April Collection 1",
    auctionType: "Online",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-04-09T19:15:00Z",
    auctionEndTime: "2021-04-23T19:15:00Z",
    title: "April Collection 2",
    auctionType: "Live",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-04-03T16:55:00Z",
    auctionEndTime: "2021-04-20T16:55:00Z",
    title: "April - Big Daddy Kane graphical Illustration",
    auctionType: "Online",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-05-10T11:10:00Z",
    auctionEndTime: "2021-05-23T11:10:00Z",
    title: "May Collection 1",
    auctionType: "Live",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-05-03T14:15:00Z",
    auctionEndTime: "2021-05-20T14:15:00Z",
    title: "May - Big Daddy Kane graphical Illustration",
    auctionType: "Online",
    coverImage: "image url",
  },
  {
    auctionStartDate: "2021-06-10T09:35:00Z",
    auctionEndTime: "2021-06-23T09:35:00Z",
    title: "Jun Collection 1",
    auctionType: "Live",
    coverImage: "image url",
  },
];
