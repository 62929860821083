import React from "react";

import Carousel from "react-bootstrap/Carousel";

import Banner from "./../../assets/image/banner.png";
import BannerTwo from "./../../assets/image/banner2.png";
import { Link } from "react-router-dom";

import { getAllSiteManagersCall } from "./../../library/Requests/SiteManager";
import { getAllCategories } from "./../../library/Requests";
import ContentLoader from "react-content-loader";
import "./styles.css";
import { triggerMixpanelEvent } from "./../../Scenes/common";

class TopBannerSlider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      allData: [],
      allCategories: [],
      isLoading: false,
      hover: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllSiteManagers();
    triggerMixpanelEvent("home_page");
  }
  getAllSiteManagers = async () => {
    this.setState({ isLoading: true });
    const resp = await getAllSiteManagersCall("1"); //.splice(1)
    this.setState({ allData: resp?.data });
    const cate = await getAllCategories();
    this.setState({ allCategories: cate?.data, isLoading: false });
  };
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  onHover = () => {
    this.setState({ hover: true });
  };
  onLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    const { allData, allCategories, isLoading, hover } = this.state;
    return (
      <>
        {isLoading ? (
          <>
            <div className="TopSectionLoading">
              <ContentLoader viewBox="0 0 100% 470" height={470} width={"100%"}>
                <rect x="100" y="0" rx="5" ry="5" width="80%" height="20" />
                <rect x="100" y="42" rx="5" ry="5" width="80%" height="400" />
              </ContentLoader>
            </div>
            <div className="HomePageCollectionsLoading">
              <ContentLoader viewBox="0 0 100% 507" height={507} width={"100%"}>
                <rect x="30" y="20" rx="0" ry="0" width="150" height="23" />
                <rect x="30" y="60" rx="0" ry="0" width="350" height="330" />
                <rect x="30" y="400" rx="0" ry="0" width="350" height="15" />
                <rect x="30" y="425" rx="0" ry="0" width="140" height="15" />

                <rect x="430" y="60" rx="0" ry="0" width="350" height="330" />
                <rect x="430" y="400" rx="0" ry="0" width="350" height="15" />
                <rect x="430" y="425" rx="0" ry="0" width="140" height="15" />

                <rect x="830" y="60" rx="0" ry="0" width="350" height="330" />
                <rect x="830" y="400" rx="0" ry="0" width="350" height="15" />
                <rect x="830" y="425" rx="0" ry="0" width="140" height="15" />
              </ContentLoader>
              <ContentLoader viewBox="0 0 100% 507" height={507} width={"100%"}>
                <rect x="30" y="20" rx="0" ry="0" width="150" height="23" />
                <rect x="30" y="60" rx="0" ry="0" width="350" height="330" />
                <rect x="30" y="400" rx="0" ry="0" width="350" height="15" />
                <rect x="30" y="425" rx="0" ry="0" width="140" height="15" />

                <rect x="430" y="60" rx="0" ry="0" width="350" height="330" />
                <rect x="430" y="400" rx="0" ry="0" width="350" height="15" />
                <rect x="430" y="425" rx="0" ry="0" width="140" height="15" />

                <rect x="830" y="60" rx="0" ry="0" width="350" height="330" />
                <rect x="830" y="400" rx="0" ry="0" width="350" height="15" />
                <rect x="830" y="425" rx="0" ry="0" width="140" height="15" />
              </ContentLoader>
            </div>
          </>
        ) : (
          <div className="custom_top_banner_slider">
            <Carousel fade>
              {(allData || []).map((i: any, index: any) => {
                const num = index + 1;
                let cateName = allCategories.find(
                  (i2: any) => i.categoryId == i2?.id
                )?.category;
                let userLink = i.textLink;
                // if (i?.articleId && i?.articleId != "") {
                //   userLink = `/viewArticle/${i?.id}`;
                // }
                // var pageLink =
                //   index === 0 ? "/auction-calendar" : `/article${index}`;
                // if (index === 1) {
                //   pageLink = "/introducingFineArtHipHop";
                // } else if (index === 2) {
                //   pageLink = "/theBirthOfHipHop";
                // } else if (index === 3) {
                //   pageLink = "/frozenMoments";
                // }
                const pageLink = userLink;
                return (
                  <Carousel.Item key={num}>
                    {/* <a target="_blank" href={userLink}> */}
                    <img
                      className="d-block w-100"
                      src={i.image}
                      alt="benner-slider-img"
                    />
                    <Carousel.Caption className="text-left">
                      <span className="position-relative carosel-cat-name font-weight-bold pl-2">
                        {index === 0 ? "Auction" : "Article"}
                      </span>
                      {/* {hover && (
                        <span className="heroHoverText">{i.title}</span>
                      )} */}
                      <h3
                        // onMouseEnter={this.onHover}
                        // onMouseLeave={this.onLeave}
                        className="mb-2 font-weight-bold heroLineClamp"
                      >
                        {i.title}
                      </h3>
                      <p className="carosel-sub-sub-text">{i.subTitle}</p>
                      <div className="carosel-btn-sec">
                        <a href={pageLink} target="_blank">
                          <button>
                            {index === 0 ? "GO TO AUCTION" : "READ"}
                          </button>
                        </a>
                      </div>
                    </Carousel.Caption>
                    {/* </a> */}
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        )}
      </>
    );
  }
}
export default TopBannerSlider;
