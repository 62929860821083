import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button, Row, Col } from "react-bootstrap";

import "./css/ActionCalendarTabContent.css";
import moment from "moment";
import filterListImg from "../../assets/image/slide1.jpg";
import noImage from "../../assets/image/noImage.jpg";
import { render } from "@testing-library/react";
import { isMobile } from "react-device-detect";
import ContentLoader from "react-content-loader";

class ActionCalendarTabContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  }

  render() {
    const {
      selectedTab,
      listDetails,
      listDetailsNew,
      isLoading,
      allCollFull,
      isResultsPage,
    } = this.props;
    const { months } = this.state;
    console.log("listDetails", listDetails);
    console.log("listDetailsNew", listDetailsNew);
    return (
      <>
        <h6 className="d-md-block d-none">{selectedTab}</h6>
        {listDetails?.length === 0 && (
          <p className="noResults noResults-auctionpafw" id="errorPage">
            No results are available in{" "}
            {isResultsPage ? "Auction Results" : "Auction Calendar"}
            {". "}
          </p>
        )}
        <div className="filter_list_data_column">
          {(listDetails || []).map((i2: any, index: any) => {
            // let d: Date = new Date(i2.auctionStartDate);
            console.log("44444444");
            // let d1: Date = new Date(i2.auctionEndTime);
            // let t: Date = new Date(i2.auctionEndTime.split('T').join(' ').split('Z')[0]);
            return (
              <div
                key={index}
                className="data_column_box data_column_box_list_view m-0 position-relative"
              >
                <div className="date-wrap position-absolute bg-white text-center d-md-block d-flex">
                  <span className={!isMobile ? "d-block dateLeft" : "d-block"}>
                    {moment(i2?.auctionStartDate).format("D ")}
                    {moment(i2?.auctionStartDate).format("MMM") !=
                      moment(i2?.auctionEndDate).format("MMM") &&
                      moment(i2?.auctionStartDate).format("MMM")}
                    {i2?.auctionEndDate && (
                      <>- {moment(i2?.auctionEndDate).format("D MMM")}</>
                    )}
                  </span>
                  {/* <span className="d-block">{moment(i2?.auctionStartDate).format("MMM")}</span> */}
                </div>
                {isLoading && (
                  <div className="spinner_Loading_iconCollectionDetails">
                    <ContentLoader
                      width={800}
                      height={575}
                      viewBox="0 0 800 575"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="537"
                        y="9"
                        rx="2"
                        ry="2"
                        width="140"
                        height="10"
                      />
                      <rect
                        x="14"
                        y="30"
                        rx="2"
                        ry="2"
                        width="667"
                        height="11"
                      />
                      <rect
                        x="12"
                        y="58"
                        rx="2"
                        ry="2"
                        width="211"
                        height="211"
                      />
                      <rect
                        x="240"
                        y="57"
                        rx="2"
                        ry="2"
                        width="211"
                        height="211"
                      />
                      <rect
                        x="467"
                        y="56"
                        rx="2"
                        ry="2"
                        width="211"
                        height="211"
                      />
                      <rect
                        x="12"
                        y="283"
                        rx="2"
                        ry="2"
                        width="211"
                        height="211"
                      />
                      <rect
                        x="240"
                        y="281"
                        rx="2"
                        ry="2"
                        width="211"
                        height="211"
                      />
                      <rect
                        x="468"
                        y="279"
                        rx="2"
                        ry="2"
                        width="211"
                        height="211"
                      />
                      <circle cx="286" cy="536" r="12" />
                      <circle cx="319" cy="535" r="12" />
                      <circle cx="353" cy="535" r="12" />
                      <rect
                        x="378"
                        y="524"
                        rx="0"
                        ry="0"
                        width="52"
                        height="24"
                      />
                      <rect
                        x="210"
                        y="523"
                        rx="0"
                        ry="0"
                        width="52"
                        height="24"
                      />
                      <circle cx="210" cy="535" r="12" />
                      <circle cx="428" cy="536" r="12" />
                    </ContentLoader>
                  </div>
                )}
                <Row className="list_view_cont">
                  <Col lg={5} className="lots_slider_img">
                    <div className="list_view_img overflow-hiidden">
                      <Link to={`/collectionDetails/${i2?.id}`}>
                        <img
                          // (i2?.coverImage !="string" || i2?.coverImage != null || i2?.coverImage != undefined || i2?.coverImage !="")
                          src={
                            i2?.coverImage != "string" ||
                            i2?.coverImage != null ||
                            i2?.coverImage != undefined ||
                            i2?.coverImage != ""
                              ? i2?.coverImage
                              : noImage
                          }
                          className="w-100"
                          alt="filter_list_img"
                        />
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={7}
                    className="lots_slider_cont_details d-flex flex-column"
                  >
                    {isMobile ? (
                      <ul className="border-list m-0 p-0 ">
                        <li className="d-inline-block position-relative">
                          {moment(i2?.auctionStartDate).format("MMMM D")}
                          {i2?.auctionEndDate && (
                            <>
                              {" "}
                              -{" "}
                              {moment(i2?.auctionEndDate).format(
                                "MMMM D, YYYY"
                              )}
                            </>
                          )}
                        </li>

                        {i2?.auctionStartTime && (
                          <>
                            <li className="d-inline-block position-relative">
                              {moment(i2?.auctionStartTime, "HHmmss").format(
                                "h:mm A"
                              )}{" "}
                              {i2?.timeZoneAbbr === "EST"
                                ? "ET"
                                : i2?.timeZoneAbbr}
                            </li>
                          </>
                        )}

                        <li className="d-inline-block position-relative d-inline-block d-md-none">
                          {i2.type} Auction
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                    <h6 className="d-md-block d-none">{i2.auctionType}</h6>
                    <h4 className="forMobileViewstyle">{i2.title}</h4>
                    {isMobile ? (
                      ""
                    ) : (
                      <ul className="border-list m-0 p-0 forMobileViewstyle">
                        <li className="d-inline-block position-relative">
                          {moment(i2?.auctionStartDate).format("MMMM D, YYYY")}
                          {i2?.auctionEndDate && (
                            <>
                              {` `}-{" "}
                              {moment(i2?.auctionEndDate).format(
                                "MMMM D, YYYY"
                              )}
                            </>
                          )}
                        </li>

                        {i2?.auctionStartTime && (
                          <>
                            <li className="d-inline-block position-relative">
                              {moment(i2?.auctionStartTime, "HHmmss").format(
                                "h:mm A"
                              )}{" "}
                              {i2?.timeZoneAbbr === "EST"
                                ? "ET"
                                : i2?.timeZoneAbbr}
                            </li>
                          </>
                        )}

                        <li className="d-inline-block position-relative d-inline-block d-md-none">
                          {i2.type} Auction
                        </li>
                      </ul>
                    )}
                    <Link
                      to={`/collectionDetails/${i2?.id}`}
                      className="browse_btn btn-solid mt-auto-top text-white text-decoration-none text-center"
                    >
                      BROWSE AUCTION
                    </Link>
                  </Col>
                </Row>
              </div>
            );
          })}

          {this.props?.isLoadingLoadMore && (
            <div className="spinner_Loading_icon">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!isLoading &&
            !this.props?.isLoadingLoadMore &&
            allCollFull?.totalCount > listDetailsNew.length &&
            allCollFull?.totalCount > 18 && (
              <div className="load_more_list_btn text-center">
                <Button
                  onClick={this.props.viewMore}
                  className="loadMore classViewMore text-white d-inline-block text-decoration-none"
                >
                  LOAD MORE
                </Button>
              </div>
            )}
        </div>
      </>
    );
  }
}
export default ActionCalendarTabContent;
