import React, { useState, useEffect } from "react";
import moment from "moment";
import * as signalR from "@microsoft/signalr";
import { SERVER_IP } from "./../../library/constants.js";
import { LOGINID } from "./../../Scenes/common";
import {
  getCollectionItemsCall,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  getTimeZones,
  unfollowbidCall,
} from "./../../library/Requests";

const TopBannerTime = (props: any) => {
  const { biddingShowText, collectionData, isPastAuction } = props;
  const [biddingShowNo, setBiddingShowNo] = useState("");
  const [biddingShowText1, setBiddingShowText1] = useState("");
  const [collectionData1, setCollectionData1] = useState({} as any);
  const [isPastCollection, setIsPastCollection] = useState(false);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .withAutomaticReconnect()
      .build();
    connection.on("BidInsertSignal", (response) => {});
    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          getLatestDataFromSignal(response);
        });
      })
      .catch((err) => console.error(err.toString()));
  }, []);
  useEffect(() => {
    if (collectionData?.id !== collectionData1?.id && collectionData?.id) {
      setCollectionData1(collectionData);
    }
  }, [collectionData?.id]);
  useEffect(() => {
    updateShowLiveActionBtn();
  }, [collectionData1]);
  useEffect(() => {
    console.log("collectionData?.endOnUtc", collectionData1?.endOnUtc);
    updateShowLiveActionBtn();
  }, [collectionData1?.endOnUtc]);

  const getLatestDataFromSignal = async (response: any) => {
    const cCID = collectionData1?.id || collectionData?.id;
    let resp = await getCollectionByIdCall(cCID, LOGINID, "topbanner");
    console.log("coll extented time ", resp?.data?.endOnUtc);
    setCollectionData1(resp?.data);
  };

  const updateShowLiveActionBtn = () => {
    if (collectionData1.auctionType == 1) {
      if (collectionData1?.startOnUtc != undefined) {
        var currUTC = moment(collectionData1?.startOnUtc).format(
          "MM/DD/YYYY hh:mm:ss A UTC"
        );
        const collTime1 = new Date(currUTC).getTime();
        const currTime1 = new Date().getTime();
        if (currTime1 < collTime1) {
          // start in
          const startEndText = "Bidding Starts in:";
          var countDownDate = collTime1;
          StartTimerPage(countDownDate, "start");
          var inrerValX = setInterval(function () {
            StartTimerPage(countDownDate, "start");
          }, 1000);
          setBiddingShowText1("Bidding Starts in:");
          removePreviousIntervals(inrerValX);
        } else if (collectionData1?.endOnUtc != undefined) {
          console.log("collectionData1?.endOnUtc", collectionData1?.endOnUtc);
          var currUTC = moment(collectionData1?.endOnUtc).format(
            "MM/DD/YYYY hh:mm:ss A UTC"
          );
          const collTime2 = new Date(currUTC).getTime();
          const currTime2 = new Date().getTime();
          if (currTime2 < collTime2) {
            //  Ends in

            var countDownDate = collTime2;
            console.log("countDownDatecountDownDate", countDownDate);
            StartTimerPage(countDownDate, "end");
            var inrerValX = setInterval(function () {
              StartTimerPage(countDownDate, "end");
            }, 1000);
            setBiddingShowText1("Bidding Ends in:");
            removePreviousIntervals(inrerValX);
          } else {
            // this.setState({ isClosedCollection: true });
          }
        }
      }
    }
  };
  const removePreviousIntervals = (inrerValX: any) => {
    const cCID = collectionData1?.id || collectionData?.id;

    const newValue = localStorage.getItem("collection_details_newInterValues");
    const newInterArray = newValue?.split("__");
    newInterArray?.map((IIMM: any) => {
      if (IIMM) {
        const interLotId = IIMM.split("_")[0];
        const interValId = IIMM.split("_")[1];
        if (interLotId == cCID) {
          window.clearInterval(parseInt(interValId));
        }
      }
    });
    var newInterV = newValue ? newValue : "";
    newInterV = newInterV ? newInterV + "__" : "";
    newInterV += `${cCID}_${inrerValX}`;
    localStorage.setItem("collection_details_newInterValues", newInterV);
  };

  const StartTimerPage = (countDownDate: any, staType: any) => {
    var now = new Date().getTime();
    if (now < countDownDate) {
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      let daysTime = "";
      var hours1 = hours > 9 ? hours : `0${hours}`;
      var minutes1 = minutes > 9 ? minutes : `0${minutes}`;
      var seconds1 = seconds > 9 ? seconds : `0${seconds}`;
      if (days > 0) {
        daysTime = days + "D " + hours1 + " : " + minutes1 + " : " + seconds1;
      } else {
        daysTime = hours1 + " : " + minutes1 + " : " + seconds1;
      }
      if (daysTime == "00 : 00 : 00") {
        // window.location.reload();
      }
      setBiddingShowNo(daysTime);
    } else if (staType === "end") {
      setIsPastCollection(true);
      // this.getCollectionData(this.state.collectionData?.id)
    }
  };
  return (
    <>
      {isPastAuction || isPastCollection ? (
        <>
          {/* <div className="past-auctions-text-con">
            <p>
              The auction for this collection has ended and we are currently
              awaiting the results. Please check back soon to find out the
              outcome.
            </p>
          </div> */}
        </>
      ) : (
        <>
          {biddingShowText != "" &&
            biddingShowNo != "" &&
            biddingShowNo != "00 : 00 : 00" && (
              <h6>
                {biddingShowText}
                <span className="font-weight-bold"> {biddingShowNo}</span>
              </h6>
            )}{" "}
        </>
      )}
    </>
  );
};
export default TopBannerTime;
