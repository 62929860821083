import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";

import AccountSlideBar from "../../components/AccountSlideBar";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment-timezone";

import BottomArrow from "../../assets/image/Bottom_arrow.svg";
import {
  handleCreateTicketCall,
  getAllTicketsCall,
  getSelectedTicketView,
  sendReplayClickMessage,
} from "../../library/Requests/MyAccount";

import "./css/Support.css";
import {
  saveSupportQueryDataCall,
  getSupportQueryDataCall,
  getAllQueriesall,
} from "./../../library/Requests/MyAccount";

const Support = (props: any) => {
  const [userQuery, setUserQuery] = useState({} as any);
  const [userQueryValue, setUserQueryValue] = useState("");
  const [replayValue, setReplayValue] = useState("");
  const [listOfQueries, setListOfQueries] = useState([] as any);
  const [listOfQueriesData, setListOfQueriesData] = useState([] as any);
  const [ticketDetails, setTicketDetails] = useState({} as any);
  const [updateStateData, setUpdateStateData] = useState(false);
  var userId = null as any;
  var userIn = null as any;
  let userInfo = localStorage.getItem("customerInfoDetails");
  if (userInfo != undefined && userInfo != null && userInfo != "") {
    userIn = JSON.parse(userInfo);
    userId = userIn?.id;
  }
  console.log("userIn", userIn);
  useEffect(() => {
    onLoadCall();
  }, []);
  const onLoadCall = async () => {
    if (userId) {
      allQCall();
      const body = {
        page: 1,
        pageSize: 20,
        customerId: userId,
      };
      const allQue = await getAllQueriesall(body);
      setListOfQueries(allQue);
    }
  };
  const allQCall = async () => {
    const allQData = await getAllTicketsCall(encodeURIComponent(userIn?.email));
    setListOfQueriesData(allQData);
  };
  const handleValueChange = (e: any) => {
    const { name, value } = e.target;
    const prev = userQuery;
    prev[name] = value;
    setUserQuery(prev);
    setUpdateStateData(!updateStateData);
  };
  const handleSubmit = async () => {
    if (userId && userQueryValue && userQuery?.queryName) {
      // const jsonBody = {
      //   submitQuery: {
      //     queryId: userQuery?.queryName,
      //     customerId: userId,
      //     description: userQueryValue,
      //   },
      // };
      // await saveSupportQueryDataCall(jsonBody);
      const jsonV = {
        name: `${userIn?.firstName} ${userIn?.lastName}`,
        description: userQueryValue,
        subject: userQuery?.queryName,
        status: 2,
        priority: 1,
        source: 2,
        requester_id: userId,
        phone: `+${userIn?.phoneCode} ${userIn?.phoneNumber}`,
        email: userIn?.email,
      };
      const newResp = await handleCreateTicketCall(jsonV);
      console.log("newResp", newResp);

      setUserQuery({});
      setUserQueryValue("");
      allQCall();
    }
  };
  const handleItemClick = async (irm: any) => {
    console.log("irm", irm);
    if (irm?.id) {
      const resp = await getSelectedTicketView(irm?.id);
      setTicketDetails(resp);
    }
  };
  const handleSendReplay = async (irm: any) => {
    console.log("irm", irm);
    if (irm?.id && replayValue) {
      const jsonV = {
        body: replayValue,
        user_id: irm?.requester_id,
        id: irm?.id,
        // from_email: userIn?.email,
      };
      const resp = await sendReplayClickMessage(jsonV);
      handleItemClick(irm);
      setReplayValue("");
      // setTicketDetails(resp);
    }
  };
  const handleReplayChange = (e: any) => {
    const { value, name } = e.target;
    setReplayValue(value);
  };
  // const handleCreateTicket = async () => {
  //   if (ticketText) {
  //     const jsonV = {
  //       name: "vinod",
  //       description: ticketText,
  //       subject: "Fahh ticket",
  //       status: 2,
  //       priority: 1,
  //       source: 2,
  //       requester_id: 129,
  //       phone: "4123434",
  //       email: "vinod@gmail.com",
  //     };
  //     const newResp = await handleCreateTicketCall(jsonV);
  //     console.log("newResp", newResp);
  //     // if (newResp?.success) {
  //     setShowSuccessMessage(true);
  //     //   // window.location.href = "/";
  //     // } else if (newResp?.errors[0]) {
  //     //   setErrorMessage(newResp?.errors[0]);
  //     // }
  //   }
  // };

  return (
    <>
      <div className="support_page_content account_all_page">
        <Container>
          <Row className="support_page_left_right_content">
            <AccountSlideBar />
            <Col lg={9}>
              <div className="support_page_right_content account_all_right_content">
                <div className="support_page_active_bid account_all_right_section">
                  <div className="account_all_right_content_heading pb-4">
                    <h4 className="mb-0">Support</h4>
                  </div>
                  <div className="support_no_activity">
                    <h6 className="pb-3 mb-0">
                      What can we help you with today?
                    </h6>
                    <Form>
                      <Form.Group controlId="formBasicEmail" className="mb-4">
                        <Form.Control
                          as="select"
                          name="queryName"
                          onChange={handleValueChange}
                          value={userQuery?.queryName}
                        >
                          <option>Please select query category</option>
                          {listOfQueries?.map((itm: any) => {
                            return (
                              <option
                                selected={
                                  userQuery?.queryName === itm?.id
                                    ? true
                                    : false
                                }
                                value={itm?.queryName}
                              >
                                {itm?.query}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        controlId="formBasicPassword"
                        className="mb-4"
                      >
                        <Form.Label className="mb-2">
                          Details of your query
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={6}
                          name="queryValue"
                          value={userQueryValue}
                          onChange={(e) => {
                            setUserQueryValue(e.target.value);
                          }}
                          placeholder="Please describe in greater detail how we can help you"
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        className="onHover"
                        onClick={handleSubmit}
                        disabled={
                          userQueryValue && userQuery?.queryName ? false : true
                        }
                      >
                        SUBMIT
                      </Button>
                    </Form>
                  </div>
                </div>
                {listOfQueriesData?.length > 0 && (
                  <div className="bids_page_past_bid support_page_active_bid account_all_right_section ">
                    <div className="account_all_right_content_heading d-flex pb-4">
                      <div className="all_acount_heading_dropdown pl-0">
                        <h4 className="mb-0">Previous Issues</h4>
                      </div>
                      <div className="all_acount_heading_dropdown pr-0">
                        {/* <Form.Control as="select" className="w-auto ml-auto">
                        <option>All</option>
                        <option>opt1</option>
                        <option>opt2</option>
                      </Form.Control> */}
                      </div>
                    </div>
                    <div className="support_no_activity">
                      <Accordion>
                        {listOfQueriesData?.map((itmQ: any, index: any) => {
                          const ind1 = index + 2;
                          return (
                            <Card>
                              <Card.Header className="bg-white p-0">
                                <Accordion.Toggle
                                  className="d-flex align-items-center w-100 text-left text-decoration-none"
                                  as={Button}
                                  variant="link"
                                  onClick={() => {
                                    handleItemClick(itmQ);
                                  }}
                                  eventKey={ind1}
                                >
                                  <p className="mb-0 mr-auto row-item-ticket">
                                    <span className="bub-bold-sec">
                                      {" "}
                                      {itmQ?.subject}:
                                    </span>
                                    <span className="desc-support-text-sec">
                                      {itmQ?.description_text}
                                    </span>

                                    <span
                                      className={`bub-bold-sec-status bub-bold-sec-status${itmQ?.status}`}
                                    >
                                      {" "}
                                      {itmQ?.status === 5
                                        ? "Closed"
                                        : itmQ?.status === 4
                                        ? "Resolved"
                                        : itmQ?.status === 2
                                        ? "Open"
                                        : itmQ?.status === 3
                                        ? "In-progress"
                                        : ""}
                                    </span>
                                    {/* <span className="d-inline-block ml-1 open_btn">
                                    open
                                  </span> */}
                                  </p>
                                  <img src={BottomArrow} />
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey={ind1}>
                                <Card.Body>
                                  {(ticketDetails?.id1 ||
                                    ticketDetails[0]?.ticket_id) ===
                                    itmQ?.id && (
                                    <div>
                                      <div>
                                        {(
                                          ticketDetails?.conversations ||
                                          ticketDetails
                                        )?.map((itemD: any) => {
                                          const isYourText =
                                            itemD?.user_id ===
                                            itmQ?.requester_id;
                                          return (
                                            <div
                                              className={
                                                isYourText
                                                  ? "row-text-item-single-now1 row-text-item-single-now-right"
                                                  : "row-text-item-single-now1 row-text-item-single-now-left"
                                              }
                                            >
                                              <div
                                                className={
                                                  isYourText
                                                    ? "row-text-item-single-now row-text-item-single-now-right1"
                                                    : "row-text-item-single-now row-text-item-single-now-left1"
                                                }
                                              >
                                                {/* <p> {itemD?.body_text}</p> */}
                                                <div
                                                  className="body-text-support-sec"
                                                  dangerouslySetInnerHTML={{
                                                    __html: itemD?.body,
                                                  }}
                                                />

                                                <p className="row-text-item-single-now-date">
                                                  <span className="bub-bold-sec">
                                                    {" "}
                                                    &#x2022;
                                                    {isYourText
                                                      ? "You"
                                                      : "Admin"}{" "}
                                                  </span>

                                                  {moment(
                                                    itemD?.created_at
                                                  ).format(
                                                    "MMMM D, YYYY, h:mm A"
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="replay-sent-sec-content">
                                        <input
                                          value={replayValue}
                                          onChange={handleReplayChange}
                                          placeholder="Write your replay"
                                        />
                                        <button
                                          onClick={() => {
                                            handleSendReplay(itmQ);
                                          }}
                                        >
                                          Send
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {/* <p className="mb-0">{itmQ?.description}</p> */}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          );
                        })}
                      </Accordion>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Support;
