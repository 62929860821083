import React, { useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Button, Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import BottomArrow from "../../assets/image/Bottom_arrow.svg";
import FilterBtn from "../../assets/image/filter.png";
import CloseIcon from "../../assets/image/close.svg";
import { isMobile } from "react-device-detect";

import "./css/ActionCalendarLeftFilter.css";
import { AnyAaaaRecord } from "dns";
import { LOGINID } from "./../../Scenes/common";

// interface Props {
//   handleTab: any;
//   tabMonths: any;
//   AllCollections?:any;
// }

class ActionCalendarLeftFilter extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterMenuOpen: false,
      selectedEvents: [],
      selectedCategories: [],
      selectedMonths: [],
      selectedYears: [],
      showLimit: 5,
    };
  }
  componentDidMount = () => {};
  FilterValueSelect = (e: any, artist: any, type: any) => {
    console.log("4555555555 vinod", type, artist);
    const self = this;
    if (type == "Events") {
      if (e.target.checked) {
        this.setState({
          ...this.state,
          selectedEvents: this.state.selectedEvents.concat(artist),
        });
      } else {
        console.log("artist vinod33", artist);

        const existData = this.state.selectedEvents;
        console.log("existData vinod33", existData);
        const index = existData.findIndex((el: any, ind: any) => el == artist);
        existData.splice(index, 1);
        this.setState({ ...this.state, selectedEvents: existData });
      }
    } else if (type == "Category") {
      if (e.target.checked) {
        this.setState({
          selectedCategories: this.state.selectedCategories.concat(artist),
        });
      } else {
        const existData = this.state.selectedCategories;
        const index = existData.findIndex((el: any, ind: any) => el == artist);
        existData.splice(index, 1);
        this.setState({ selectedCategories: existData });
      }
    } else if (type === "Month") {
      if (e.target.checked) {
        this.setState({
          selectedMonths: this.state.selectedMonths.concat(artist),
        });
      } else {
        const existData = this.state.selectedMonths;
        const index = existData.findIndex((el: any, ind: any) => el == artist);
        existData.splice(index, 1);
        this.setState({ selectedMonths: existData });
      }
    } else if (type === "Year") {
      if (e.target.checked) {
        this.setState({
          selectedYears: this.state.selectedYears.concat(artist),
        });
      } else {
        const existData = this.state.selectedYears;
        const index = existData.findIndex((el: any, ind: any) => el == artist);
        existData.splice(index, 1);
        this.setState({ selectedYears: existData });
      }
    }
    setTimeout(() => {
      self.getRefreshData();
    }, 10);
  };
  clearAllFilters = (e: any, type: any) => {
    const self = this;
    if (type == "ClearAll") {
      if (e.target.click) {
        document
          .querySelectorAll("input[type=checkbox]")
          .forEach((el: any) => (el.checked = false));
        this.setState({
          selectedEvents: [],
          selectedCategories: [],
          selectedMonths: [],
          selectedYears: [],
        });
      } else {
        this.setState({ ...this.state });
      }
    }
    setTimeout(() => {
      self.getRefreshData();
    }, 10);
  };
  getRefreshData = () => {
    const {
      selectedEvents,
      selectedCategories,
      selectedMonths,
      selectedYears,
    } = this.state;

    let body: any = {};
    body = {
      page: 1,
      pageSize: 3,
      getOnlyTotalCount: false,
      collectionId: this.props.collectionid,
      customerId: LOGINID,
    };
    if (selectedEvents.length > 0) {
      body.auctionType = selectedEvents;
    }
    if (selectedCategories.length > 0) {
      body.categoryId = selectedCategories;
    }
    if (selectedMonths.length > 0) {
      body.months = selectedMonths;
    }
    if (selectedYears.length > 0) {
      body.years = selectedYears;
    }

    this.props.getUpdatedDataCall(body);
  };
  clickShowMore = () => {
    const { showLimit } = this.state;
    this.setState({ showLimit: showLimit + 5 });
  };

  render() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const thisMonth: any = months[new Date().getMonth()];

    const { handleTab, tabMonths, AllCollections } = this.props;
    const { filterMenuOpen, showLimit } = this.state;
    const categoriesList = this.props?.AllCollectionsFull?.categories;
    const auctionTypeList = this.props?.AllCollectionsFull?.auctionType;
    const monthsList = this.props?.AllCollectionsFull?.months;
    const yearsList = this.props?.AllCollectionsFull?.years;
    console.log("tabMonths filter", tabMonths);
    return (
      <>
        <div className="action_calendar_left_filter">
          <div className="custom_slider_heading_filter pt-0 d-flex align-items-center">
            <h3 className="mb-0 position-relative pl-3">
              <span className="d-md-inline-block d-none">Auction</span>{" "}
              {this.props?.isResultsPage ? "Results" : "Calendar"}
            </h3>
            <Form.Group
              controlId="exampleForm.ControlSelect1"
              className="ml-auto d-md-none"
            >
              <Form.Control
                as="select"
                onChange={(event) => handleTab(event.target.value)}
              >
                <option value="all">All</option>
                {tabMonths.map((m: any) => {
                  return (
                    <option key={m} value={m}>
                      {thisMonth === m ? "This Month" : m}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="action_calenda_filter_menu">
            <h3
              className="filter_heading"
              onClick={() => {
                this.setState({ filterMenuOpen: true });
              }}
            >
              <img
                src={FilterBtn}
                alt="filter-btn"
                className="mr-2 d-inline-block d-lg-none"
              />
              Filter By
              {!isMobile && (
                <span
                  className="clearAllStyles"
                  onClick={(e) => {
                    this.clearAllFilters(e, "ClearAll");
                  }}
                >
                  Clear all
                </span>
              )}
            </h3>
            <Accordion
              defaultActiveKey="0"
              className={`${filterMenuOpen && "active"}`}
            >
              <div className="responsive_menu_close mb-3 d-flex align-items-center d-lg-none">
                <Link
                  to="/auction-calendar"
                  className="mr-auto text-decoration-none"
                  onClick={(e) => {
                    this.clearAllFilters(e, "ClearAll");
                  }}
                >
                  Clear All
                </Link>
                <div
                  className="close_icon"
                  onClick={() => {
                    this.setState({ filterMenuOpen: false });
                  }}
                >
                  <img src={CloseIcon} alt="close_icon" />
                </div>
              </div>
              <div className="mobile_right_sidebar_content">
                {this.props?.isResultsPage && (
                  <>
                    <Card className="rounded-0 border-0">
                      <Card.Header className="p-0 border-0 bg-transparent">
                        <Accordion.Toggle
                          className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                          as={Button}
                          variant="link"
                          eventKey="0"
                        >
                          <span className="mr-auto d-block">Year</span>
                          <img src={BottomArrow} alt="dropdown arrow" />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body className="p-0">
                          <div className="filter_list">
                            {(yearsList || []).map((i: any, index: any) => {
                              return (
                                <Form.Group
                                  controlId={`Year${index}`}
                                  key={`Year${index}`}
                                >
                                  <Form.Check
                                    onClick={(e: any) => {
                                      this.FilterValueSelect(e, i, "Year");
                                    }}
                                    type="checkbox"
                                    label={i}
                                  />
                                </Form.Group>
                              );
                            })}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="rounded-0 border-0">
                      <Card.Header className="p-0 border-0 bg-transparent">
                        <Accordion.Toggle
                          className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                          as={Button}
                          variant="link"
                          eventKey="1"
                        >
                          <span className="mr-auto d-block">Month</span>
                          <img src={BottomArrow} alt="dropdown arrow" />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="p-0">
                          <div className="filter_list">
                            {(monthsList || []).map((i: any, index: any) => {
                              return (
                                <Form.Group
                                  controlId={`Month${index}`}
                                  key={`Month${index}`}
                                >
                                  <Form.Check
                                    onClick={(e: any) => {
                                      this.FilterValueSelect(e, i, "Month");
                                    }}
                                    type="checkbox"
                                    label={i}
                                  />
                                </Form.Group>
                              );
                            })}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </>
                )}
                <Card className="rounded-0 border-0">
                  <Card.Header className="p-0 border-0 bg-transparent">
                    <Accordion.Toggle
                      className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                      as={Button}
                      variant="link"
                      eventKey="2"
                    >
                      <span className="mr-auto d-block">Events</span>
                      <img src={BottomArrow} alt="dropdown arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="p-0">
                      <div className="filter_list">
                        {(auctionTypeList || []).map((i: any) => {
                          return (
                            <Form.Group controlId={i.type} key={i.type}>
                              <Form.Check
                                onClick={(e: any) => {
                                  this.FilterValueSelect(e, i?.type, "Events");
                                }}
                                disabled={i.auction === "Live" ? true : false}
                                type="checkbox"
                                label={`${i.auction} (${i.count})`}
                              />
                            </Form.Group>
                          );
                        })}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/* <Card className="rounded-0 border-0">
                  <Card.Header className="p-0 border-0 bg-transparent">
                    <Accordion.Toggle
                      className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                      as={Button}
                      variant="link"
                      eventKey="3"
                    >
                      <span className="mr-auto d-block">Category</span>
                      <img src={BottomArrow} alt="dropdown arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body className="p-0">
                      <div className="filter_list">
                        {(categoriesList || []).map((i2: any, index: any) => {
                          return (
                            <>
                              {showLimit > index && (
                                <Form.Group controlId={i2.id} key={i2.id}>
                                  <Form.Check
                                    onClick={(e: any) => {
                                      this.FilterValueSelect(
                                        e,
                                        i2?.id,
                                        "Category"
                                      );
                                    }}
                                    type="checkbox"
                                    label={i2.category}
                                  />
                                </Form.Group>
                              )}
                            </>
                          );
                        })}
                        {showLimit < categoriesList?.length && (
                          <Button
                            onClick={this.clickShowMore}
                            className="show_more_filter_list text-decoration-none"
                          >
                            Show More
                          </Button>
                        )}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> */}
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}
export default ActionCalendarLeftFilter;
